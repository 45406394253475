<template>
    <svg
      :width="width"
      :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 2.72766H21.4C21.7713 2.72766 22.1274 2.87516 22.3899 3.13771C22.6525 3.40026 22.8 3.75636 22.8 4.12766V20.9277C22.8 21.299 22.6525 21.6551 22.3899 21.9176C22.1274 22.1802 21.7713 22.3277 21.4 22.3277H13" :stroke="iconColor" stroke-width="1.4"/>
        <path d="M15.5732 12.1277L9.97319 6.52771M15.5732 12.1277L9.97319 17.7277M15.5732 12.1277H2.55493" :stroke="iconColor" stroke-width="1.4"/>
        <circle cx="3.71636" cy="12.1277" r="1.21636" fill="white" :stroke="iconColor"/>
    </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconLsConnecto'
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 25
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>