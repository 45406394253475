import Vue from "vue";
import axios from "axios";
import i18n from "@/lang/i18n";

const state = {
  broadcast: {
		languages: [],
    defaultLang: '',
    currentLang: '',
    defaulTemplateData: {
      id: '',
      templateName: '',
      showBack: false,
      alias: '',
      topic_id: null,
      values: {},
      create: true,
      Markdown: false,
      MarkdownV2: false,
    },
    initTemplateData: {
      id: '',
      templateName: '',
      showBack: false,
      alias: '',
      topic_id: null,
      values: {},
      create: true,
      Markdown: false,
      MarkdownV2: false,
      // parse_mode: 'html',
      // parse_mode: 'Markdown',
      // parse_mode: 'MarkdownV2',
    },
  },  
};

const mutations = {
  SET_DEFAULT_LANG (state, value) {
    state.broadcast.defaultLang = value;
    state.broadcast.currentLang = value;
  },
  SET_LANGS_VALUES_DEFAULT (state, langs) {
    state.broadcast.defaulTemplateData.values = langs;
  },
  SET_LANGS_VALUES_INIT (state, langs) {     
    state.broadcast.initTemplateData.values = langs;
  },
  SET_CURRENT_LANG (state, lang) {
    state.broadcast.currentLang = lang;    
  },
  SET_VALUE_KEY (state, params) {
    let currentLang, type, value;   
    [currentLang, type, value] = [...params];

    state.broadcast.initTemplateData.values[currentLang][type] = value;
  },
  SET_TEMPLATE_NAME (state, value) {
    state.broadcast.initTemplateData.templateName = value;
  },
  SET_TEMPLATE_ALIAS (state, value) {
    state.broadcast.initTemplateData.alias = value;
  },
  SET_TEMPLATE_THEME (state, value) {
    state.broadcast.initTemplateData.topic_id = value;
  },
  SET_TEMPLATE_ID (state, value) {
    state.broadcast.initTemplateData.id = value;
  },
  SET_INIT_TEMPLATE_DATA (state, values) {
    state.broadcast.initTemplateData = values;
  },
  SET_TEMPLATE_CREATE(state, value) {
    state.broadcast.initTemplateData.create = value
  },
  SET_TEMPLATE_MARKDOWN(state, value) {
    state.broadcast.initTemplateData.Markdown = value
  },
  SET_TEMPLATE_MARKDOWNV_TWO(state, value) {
    state.broadcast.initTemplateData.MarkdownV2 = value
  },
	SET_BROADCAST_LANGUAGES(state, arr) {
		Vue.set(state.broadcast, 'languages', arr);
	},
};

const actions = {
  async set_default_lang ({ commit, state, dispatch }, botId) {
		if(!botId) return;

		let token = Vue.$cookies.get('token_e');
		let url = state.engine_settings.active_url;
		let bot_id = botId;

		await axios
			.get(url + '/kw/languages/' + bot_id + '/', {
				headers: {
					'X-CSRF-TOKEN': token,
				},
			})
			.then(
				(response) => {
					if (response?.data?.data?.languages) {

						let languages = response.data.data.languages;
						for (let lang of languages) {
							if (lang.default && lang.code) {
								commit('SET_DEFAULT_LANG', lang.code)
							}
						}

						commit('SET_BROADCAST_LANGUAGES', languages);
						dispatch('updateAjaxDialog', [false, false]);

					}else{
						dispatch('updateAjaxDialogError', [
							'Error',
							i18n.t('modules.dialog.texts.settings.error_language_list')
						]);
					}

				},
				(err) => {
					dispatch('updateAjaxDialogError', [
						err,
						'Error',
						i18n.t('modules.dialog.texts.settings.error_language_list')
					]);
				}
			);

  },
  set_default_lang_values ({ commit, state }) {    
    let obj = {}

    for(let lang of state.broadcast.languages) {
      obj[lang.code] = {
        message: '', 
        fileLink: '', 
        fileBody: null,
      }
    }

		commit('SET_LANGS_VALUES_DEFAULT', obj)
  },
  set_init_lang_values ({ commit, state }) {    
    let obj = {}

    for(let lang of state.broadcast.languages) {
      obj[lang.code] = {
        message: '', 
        fileLink: '', 
        fileBody: null,
      }
    }

		commit('SET_LANGS_VALUES_INIT', obj)
  },
  set_value_key ({ commit }, params) {
    commit('SET_VALUE_KEY', params)
  },
  set_current_lang ({ commit }, value) {
    commit('SET_CURRENT_LANG', value)
  },
  set_template_name ({ commit }, value) {
    commit('SET_TEMPLATE_NAME', value)
  },
  set_template_alias ({ commit }, value) {
    commit('SET_TEMPLATE_ALIAS', value)
  },
  set_template_theme ({ commit }, value) {
    commit('SET_TEMPLATE_THEME', value)
  },
  set_template_id ({ commit }, value) {
    commit('SET_TEMPLATE_ID', value)
  },
  set_init_template_data({ commit }, values) {
    commit('SET_INIT_TEMPLATE_DATA', values)
  },
  set_template_create({ commit }, value) {
    commit('SET_TEMPLATE_CREATE', value)
  },
  set_template_markdown ({ commit }, value) {
    commit('SET_TEMPLATE_MARKDOWN', value)
    if(!value) commit('SET_TEMPLATE_MARKDOWNV_TWO', value)
  },
  set_template_markdownv_two ({ commit }, value) {
    commit('SET_TEMPLATE_MARKDOWNV_TWO', value)
  },
};

const getters = {
  default_lang: (state) => {    
    return state.broadcast.defaultLang;
  },
  values_langs: (state) => {
    return state.broadcast.initTemplateData.values;
  },
  current_lang: (state) => {
    return state.broadcast.currentLang;
  },
  default_template_data: (state) => {
    return state.broadcast.defaulTemplateData;
  },
  init_template_data: (state) => {
    return state.broadcast.initTemplateData;
  },
  template_name: (state) => {
    return state.broadcast.initTemplateData.templateName;
  },
  template_alias: (state) => {
    return state.broadcast.initTemplateData.alias;
  },
  template_theme: (state) => {
    return state.broadcast.initTemplateData.topic_id;
  },
  template_id: (state) => {
    return state.broadcast.initTemplateData.id;
  },
  template_markdown: (state) => state.broadcast.initTemplateData.Markdown,
  template_markdownv_two: (state) => state.broadcast.initTemplateData.MarkdownV2,
	broadcast_languages: (state) => state.broadcast.languages
};

export default {
  state,
  mutations,
  actions,
  getters,
};