export default {
  data() {
    return {
      globalFilterExist: false,
      dynamicSelects: 0,
      vmodelGlobalFilterModel: [],
      vmodelGlobalFilterColumn: [],
      itemsForGlobalFilterColumn: [],
      payloadGlobalFilter: [{
        model_id: null
      }],
      screenWidth: window.innerWidth,

      // mockupGlobalFilter: [
      //   {
      //     model_id: 51, 
      //     column_name: "managers_phone",
      //     select: [
      //       {"name": "managers_phone"},
      //       {"city": 12}
      //     ]
      //   },
      //   {
      //     model_id: 61, 
      //     column_name: "city_custom", 
      //     select: [
      //       {"name": "value_id"},
      //       {"city": 12}
      //     ]
      //   },
      // ],
      mockupGlobalFilter: [
        {
          id: 5,
          custom_module_id: 51,
          kw_module_name: null,
          user_id: "1",
          name: "filter_local",
          filters_json: [
            [
              "managers_phone",
              {
                type: "STRING",
                label: "Номер телефону",
                value: "34",
                condition: {
                  text: "містить",
                  value: "like"
                },
                is_association: false
              }
            ]
          ],
          columns_chooser: null,
          createdAt: "2024-02-22T07:43:11.000Z",
          updatedAt: "2024-02-22T07:43:11.000Z"
        }
      ],
    };
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    addItemIntoGlobalFilter() {
      if(this.globalFilterExist) {
        console.log('add new row to exist filter')
      } else {
        this.dynamicSelects++

        if (!this.payloadGlobalFilter[this.dynamicSelects - 1]) {
          this.payloadGlobalFilter.push({
            model_id: null
          });
        }
      }
    },
    changeGlobalFilterModelSelect(index) {
      this.payloadGlobalFilter[index].model_id = null
      this.vmodelGlobalFilterColumn[index] = null
      const columnNamesArray = Object.keys(this.vmodelGlobalFilterModel[index].columns_json);
      
      this.itemsForGlobalFilterColumn[index] = columnNamesArray;
    },
    changeGlobalFilterColumnSelect(index) {
      this.payloadGlobalFilter[index].model_id = this.vmodelGlobalFilterModel[index].id

      if(this.itemsForGlobalFilterColumn[index].includes(this.vmodelGlobalFilterColumn[index])) {
        delete this.payloadGlobalFilter[index].association_field
        this.payloadGlobalFilter[index].column_name = this.vmodelGlobalFilterColumn[index]
      } else {
        delete this.payloadGlobalFilter[index].column_name
        this.payloadGlobalFilter[index].association_field = this.vmodelGlobalFilterColumn[index]
      }
    },
    deleteGlobalFilterItem(index) {
      this.dynamicSelects--
      this.vmodelGlobalFilterModel.splice(index, 1)
      this.vmodelGlobalFilterColumn.splice(index, 1)
      this.itemsForGlobalFilterColumn.splice(index, 1)
      if(this.dynamicSelects) {
        this.payloadGlobalFilter.splice(index, 1)
      } else {
        this.payloadGlobalFilter[0].model_id = null
      }
    },
    saveFirstGlobalFilter() {
      // console.log(this.payloadGlobalFilter)
			this.applyFilter()
      console.log(this.dates)
    },

    handleResize() {
      this.screenWidth = window.innerWidth;
    }
  },

  computed: {
    disabledButtonForGlobalFilter() {
      return this.payloadGlobalFilter.some(item => item.model_id == null)
    },
    isDesktop() {
      return this.screenWidth > 1300;
    }
  }
};
