import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/Polls/layout/Layout.vue';

export default [
  {
    path: '/polls/:id',
    component: LAYOUT.base,
		name: 'Polls',
    redirect: '/polls/:id/section',
    children: [
      {
        path: 'section',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'PollsSection',
            component: () => import(/* webpackChunkName: 'polls-section' */ '@/modules/Polls/views/SectionTab.vue'),
          },
        ],
      },
    ],
  },
];
