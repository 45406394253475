<template>
  <v-container
    :fluid="fluid_mode"
  >
    <v-row>
      <v-col v-if="show_header" cols="12" class="pb-0">
        <slot name="header"></slot>
      </v-col>
      <v-col cols="12" md="6">
        <v-stepper
          v-model="inputVal"
          non-linear
          vertical
          flat
          class="onboard-stepper"
        >
          <slot name="steps"></slot>

        </v-stepper>
      </v-col>
      <v-col cols="12" md="6">
        <slot name="right"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Stepper",

  props: {
    value: {
      type: [String, Number]
    },
    fluid_mode: {
      type: Boolean,
      default: false
    },
    show_header: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({}),

  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.onboard-stepper::v-deep {
  $this: 'onboard-stepper';
  &.theme--light.v-stepper {
    background: transparent;
  }
  .v-stepper__step__step {
    min-width: 50px;
    height: 50px;
    font-size: 18px;
  }
  &.v-stepper--vertical .v-stepper__content {
    margin-left: 48px !important;
  }

  &.v-stepper--vertical .v-stepper__step {
    padding: 24px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .v-stepper__step {
      padding: 12px !important;
    }
    &.v-stepper--vertical .v-stepper__content {
      margin-left: 0 !important;
      border: none !important;
      padding-left: 0;
    }
    .v-stepper__step__step {
      min-width: 30px;
      height: 30px;
      font-size: 14px;
    }
  }
}

</style>