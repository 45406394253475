<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8889 5.66669C14.7552 5.66669 17.6217 7.21109 20.2811 10.3L21.8889 12.1667L20.2811 14.0334C19.7147 14.6913 19.1388 15.2791 18.5556 15.797M8.00003 6.75002C6.41547 7.51085 4.98769 8.56829 3.49669 10.3L1.88892 12.1667L3.49669 14.0334C7.33458 18.491 11.6035 19.7322 15.6814 17.7569L8.00003 6.75002Z"
              :stroke="iconColor" stroke-width="1.4" stroke-linecap="round"/>
        <path d="M5.88892 1.66669L18.8889 21.6667M10.5918 8.88891C9.71601 9.48613 9.13892 10.5072 9.13892 11.6667C9.13892 13.5077 10.5939 15 12.3889 15C13.1017 15 13.4781 14.8436 14.0139 14.4445L10.5918 8.88891ZM14.0139 8.77935C14.208 8.89446 14.3895 9.02969 14.5556 9.18213C14.958 9.55158 15.2703 10.0225 15.454 10.5556C15.5323 10.7829 15.5873 11.0216 15.6159 11.2685L14.0139 8.77935Z"
              :stroke="iconColor" stroke-width="1.4" stroke-linecap="round"/>
    </svg>


</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconEye'
            },
            width: {
                type: [Number, String],
                default: 24
            },
            height: {
                type: [Number, String],
                default: 24
            },
            iconColor: {
                type: String,
                default: '#050038'
            }
        }
    }
</script>