<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.4138 12C22.4138 12 19.2897 18.2482 12.0001 18.2482C4.71053 18.2482 1.58643 12 1.58643 12C1.58643 12 4.71053 5.75177 12.0001 5.75177C19.2897 5.75177 22.4138 12 22.4138 12Z"
              :stroke="iconColor" stroke-width="1.4" stroke-linecap="square"/>
        <path d="M12.0001 15.1241C13.7255 15.1241 15.1242 13.7254 15.1242 12C15.1242 10.2746 13.7255 8.87592 12.0001 8.87592C10.2747 8.87592 8.87598 10.2746 8.87598 12C8.87598 13.7254 10.2747 15.1241 12.0001 15.1241Z"
              :stroke="iconColor" stroke-width="1.4" stroke-linecap="square"/>
    </svg>

</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconEye'
            },
            width: {
                type: [Number, String],
                default: 24
            },
            height: {
                type: [Number, String],
                default: 24
            },
            iconColor: {
                type: String,
                default: '#050038'
            }
        }
    }
</script>