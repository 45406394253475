<template>
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1" fill="white">
            <rect x="0.126221" y="8.29791" width="16" height="12" rx="1"/>
        </mask>
        <rect x="0.126221" y="8.29791" width="16" height="12" rx="1" :stroke="iconColor" stroke-width="2.8" mask="url(#path-1-inside-1)"/>
        <path d="M13.0176 9.60023V5.89143C13.0176 3.18997 10.8276 1 8.12617 1V1C5.42471 1 3.23474 3.18997 3.23474 5.89143V9.60023" :stroke="iconColor" stroke-width="1.4"/>
    </svg>


</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconPasswd'
            },
            width: {
                type: [Number, String],
                default: 17
            },
            height: {
                type: [Number, String],
                default: 21
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>