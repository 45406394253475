<template>
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      id="Layer_1" 
      data-name="Layer 1" 
      viewBox="0 0 24 24" 
      width="512" 
      height="512"
    >
      <path 
        :fill="iconColor" 
        d="m2,3.5c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5-3.5-1.57-3.5-3.5Zm13.808,3.5c1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5-3.5,1.57-3.5,3.5,1.57,3.5,3.5,3.5Zm-1.065,1c.785.951,1.257,2.17,1.257,3.5,0,.169-.015.335-.03.5h5.03v-1c0-1.657-1.343-3-3-3h-3.257Zm5.257,10v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4Zm-9.5-10c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5,3.5-1.57,3.5-3.5-1.57-3.5-3.5-3.5Zm-5.5,11v3h7v-6h-4c-1.654,0-3,1.346-3,3Zm0-7.5c0-1.33.472-2.549,1.257-3.5h-3.257c-1.657,0-3,1.343-3,3v3h5.605c-.384-.751-.605-1.599-.605-2.5Z"
      />
    </svg>

</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'iconAddChatUser'
      },
      width: {
        type: [Number, String],
        default: 20
      },
      height: {
        type: [Number, String],
        default: 9
      },
      iconColor: {
        type: String,
        default: '#5784eb'
      }
    }
  }
</script>