<template>
  <div class="cd__image-list">
    <CdImageListItem :imageUrl="item.url" v-for="item of imageListObject" :key="item.id" />
  </div>
</template>

<script>
import CdImageListItem from './CdImageListItem.vue';

export default  {
  name: 'CdImageList',

  components: {
    CdImageListItem,
  },

  props: {
    imageListObject: {
      type: Array
    }
  },
  data: () => ({

  }),

  computed: {
    
  },

  mounted() {
    
  },

  methods: {
    
  },
}
</script>

<style scoped lang="scss">
.cd__image-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
}
</style>
