<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4019 5.27571C12.4017 5.83702 12.291 6.39279 12.076 6.9113C11.861 7.42981 11.546 7.9009 11.149 8.29767C10.7519 8.69444 10.2806 9.00913 9.76199 9.22376C9.24334 9.43839 8.68749 9.54876 8.12618 9.54857C7.28081 9.54829 6.4545 9.29733 5.75176 8.82743C5.04901 8.35754 4.50139 7.6898 4.17814 6.90867C3.85489 6.12754 3.77053 5.26809 3.93573 4.43902C4.10093 3.60994 4.50827 2.84847 5.10624 2.2509C5.70421 1.65333 6.46595 1.2465 7.29514 1.08185C8.12432 0.917206 8.98371 1.00214 9.76462 1.32591C10.5455 1.64968 11.2129 2.19775 11.6823 2.90081C12.1518 3.60387 12.4022 4.43034 12.4019 5.27571ZM15.2524 21L1 20.9419V18.0971C1 16.9632 1.45048 15.8756 2.25233 15.0738C3.05418 14.2719 4.14172 13.8214 5.27571 13.8214H10.9767C12.1106 13.8214 13.1982 14.2719 14 15.0738C14.8019 15.8756 15.2524 16.9632 15.2524 18.0971V20.9433V21Z"
              :stroke="iconColor"
              stroke-width="1.4" stroke-linecap="square"/>
    </svg>

</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconLogin'
            },
            width: {
                type: [Number, String],
                default: 16
            },
            height: {
                type: [Number, String],
                default: 22
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>