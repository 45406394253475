import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/FastLine/layout/Layout.vue';

export default [
  {
    path: '/fastline/:id',
    component: LAYOUT.base,
		name: 'FastLine',
    redirect: '/fastline/:id/section',
    children: [
      {
        path: 'section',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'FastLineSection',
            component: () => import(/* webpackChunkName: 'file-manager-files' */ '@/modules/FastLine/views/FastLine.vue'),
          },
        ],
      },
    ],
  },
];
