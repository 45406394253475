// import axios from "axios";
// import moment from 'moment'


export default {
    install(Vue, options) {

        //Проверка существования svg ноды и node объекта. Boolean
        Vue.prototype.$checkNodeAndSvgNodeById = function(vm, node_id){
            if(vm.$checkSvgNodeById(vm, node_id) && vm.$checkNodeById(vm, node_id)) {
                return true
            }
            return false
        }

        //Проверка существования svg ноды. Boolean
        Vue.prototype.$checkSvgNodeById = function(vm, node_id){
            if(vm && node_id && vm.svg_nodes && vm.svg_nodes[node_id]) {
                return true
            }
            return false
        }

        //Проверка существования нод объекта. Boolean
        Vue.prototype.$checkNodeById = function(vm, node_id){
            if(vm && node_id && vm.nodes && vm.nodes[node_id]) {
                return true
            }
            return false
        }

        //Получает текущие координаты svg элемента
        Vue.prototype.$getCurrentSvgNodePosition = function(vm, node_id){
            let coordinates = {
                x: null,
                y: null
            }

            let svg_node = vm.svg_nodes[node_id]
            coordinates.x = parseInt(svg_node.first().attr('x'))
            coordinates.y = parseInt(svg_node.first().attr('y'))

            return coordinates
        }

        //Получает текущие координаты svg элемента и записывает их в стор если были изменения
        Vue.prototype.$getCurrentSvgNodePositionAndUpdateStore = function(vm, node_id){
            let node = vm.nodes[node_id]
            let svg_node = vm.svg_nodes[node_id]

            let svg_coordinates = vm.$getCurrentSvgNodePosition(vm, node_id)

            if (node.x !== svg_coordinates.x || node.y !== svg_coordinates.y) {
                node.x = svg_coordinates.x
                node.y = svg_coordinates.y

                vm.$store.dispatch('updateNode', node)
            }
        }

        //отдает объект их координат точек двух блоков для построения соединительной линии между ними
        Vue.prototype.$getTwoElementsConnectionDotsCoordinates = function(vm, from_id, to_id){

            let r1_coordinates = vm.$getElementAllPossibleExternalConnectionDotsCoordinates(vm, from_id)
            let r2_coordinates = vm.$getElementAllPossibleExternalConnectionDotsCoordinates(vm, to_id)

            let dot_1_x = null
            let dot_1_y = null
            let dot_2_x = null
            let dot_2_y = null
            let total_sum = null
            for(let pair1 of r1_coordinates){
                if(dot_1_x === null){
                    dot_1_x = pair1.x
                    dot_1_y = pair1.y
                }
                for(let pair2 of r2_coordinates){
                    if(dot_2_x === null){
                        dot_2_x = pair2.x
                        dot_2_y = pair2.y
                        total_sum = Math.abs(dot_2_x - dot_1_x) + Math.abs(dot_2_y - dot_1_y)
                    }

                    let pairs_sum =  Math.abs(pair2.x - pair1.x) + Math.abs(pair2.y - pair1.y)
                    if(pairs_sum < total_sum){
                        dot_1_x =  pair1.x
                        dot_1_y =  pair1.y
                        dot_2_x =  pair2.x
                        dot_2_y = pair2.y
                        total_sum = pairs_sum
                    }
                }
            }

            let c = {
                x1: dot_1_x,
                y1: dot_1_y,
                x2: dot_2_x,
                y2: dot_2_y
            }

            return c
        },

        //Отдает все возможные точки блока которые могут использоваться для соединения с другими блоками. Отображение 4ех внешних точек
        Vue.prototype.$getElementAllPossibleExternalConnectionDotsCoordinates = function(vm, node_id){

            let rect = vm.svg_nodes[node_id].first()

            let x1 = rect.attr('x')
            let y1 = rect.attr('y')
            let w = rect.attr('width')
            let h = rect.attr('height')

            let x2 = parseFloat(x1) + parseFloat(w)
            let y2 = parseFloat(y1) + parseFloat(h)

            let dx = parseFloat(x1) + parseFloat(w)/2
            let dy = parseFloat(y1) + parseFloat(h)/2

            let dots = [
                {
                    x: dx,
                    y: y1
                },
                {
                    x: x2,
                    y: dy
                },
                {
                    x: dx,
                    y: y2
                },
                {
                    x: x1,
                    y: dy
                }

            ]

            return dots

        },

        //Отдает координаты точки элемента для соедиенения с другими элементами. Точка посередине внизу блока
        Vue.prototype.$getElementDefaultExternalConnectionDotCoordinates = function(vm, node_id){

            let r1_coordinates = vm.$getElementAllPossibleExternalConnectionDotsCoordinates(vm, node_id)

            return r1_coordinates[2]

        }

        /* Точка соединения */
        //Получение координат точки
        Vue.prototype.$getDotCoordinates = function(vm, node_id, dot_index){

            let coordinates = {
                x: null,
                y: null
            }

            let line_params = vm.$defineLineParams(vm, node_id, dot_index)

            vm[line_params.el][line_params.key].each(function(i, children) {
                if(this.type && this.type === 'circle'){
                    coordinates.x = this.cx()
                    coordinates.y = this.cy()
                }
            }, false)

            return coordinates
            
        },

        //Стилизация точки соединения
        Vue.prototype.$styleConnectionDot = function(vm, node_id, dot_index, tpl, force = false){

            let line_params = vm.$defineLineParams(vm, node_id, dot_index)
            
            if(!['hover', 'default', 'active'].includes(tpl)){
                return
            }

            let style = {
                default: {
                    fill_color: 'white',
                    stroke_color: 'blue'
                },
                hover: {
                    fill_color: 'blue',
                    stroke_color: 'white'
                },
                active: {
                    fill_color: '#E2E7F2',
                    stroke_color: 'blue'
                },
            }

            //если текущий блок активен - обводку менять не нужно
            if (force === false && vm.active_elements.dot_node_id && vm.active_elements.dot_node_id === node_id && ['hover', 'default'].includes(tpl)) {
                return
            }

            vm[line_params.el][line_params.key].each(function(i, children) {
                if(this.type && this.type === 'circle'){
                    this.fill(style[tpl].fill_color)

									if(tpl === 'active') {
										this.animate(300, '<>').size(20)
									} else {
										this.animate(300, '<>').size(10)
									}
                }else {
                    this.stroke({color: style[tpl].stroke_color})
                }
            }, false)

        }

        //Активировать точку соединения для блока
        Vue.prototype.$activateConnectionDot = function(vm, node_id, dot_index){
            //деактивация текущей активной точки
            vm.$deactivateConnectionDot(vm)

            //установка новой активной точки
            vm.active_elements.dot_node_id = node_id
            vm.active_elements.dot_index = dot_index

            //Применение стилей
            vm.$styleConnectionDot(this, node_id, dot_index, 'active')
        },

        //Деактивировать точку соединения для блока
        Vue.prototype.$deactivateConnectionDot = function(vm){
            if(vm.active_elements.dot_node_id) {
                vm.$styleConnectionDot(vm, vm.active_elements.dot_node_id, vm.active_elements.dot_index, 'default', true)
            }

            //Обнуление параметров
            vm.active_elements.dot_node_id = null
            vm.active_elements.dot_index = null
        }

        //Скрытие точки
        Vue.prototype.$hideConnectionDot = function(vm, node_id, dot_index){

            let line_params = vm.$defineLineParams(vm, node_id, dot_index)
            
            if(vm[line_params.el][line_params.key]) {
                vm[line_params.el][line_params.key].hide()
            }
        },

        //Отображение внешней точки
        Vue.prototype.$showConnectionDot = function(vm, node_id, dot_index){
            let line_params = vm.$defineLineParams(vm, node_id, dot_index)

            if(vm[line_params.el][line_params.key]) {
                vm[line_params.el][line_params.key].show()
            }
        }
        /* Точка соединения */

        /* Каркас элемента */
        //Стилизация каркаса ноды
        Vue.prototype.$styleNodeBaseStructure = function(vm, node_id, tpl, force = false){

            if(!['hover', 'default', 'active'].includes(tpl)){
                return
            }

            let style = {
                default: {
                    fill_color: 'white',
                    stroke_color: '#F5F6FA'
                },
                hover: {
                    fill_color: '#fffde6',
                    stroke_color: "#D6DCFA"
                },
                active: {
                    fill_color: 'white',
                    stroke_color: '#D6DCFA'
                },
            }

            //если текущий блок активен - обводку менять не нужно
            if (force === false && vm.active_node_id && vm.active_node_id === node_id && ['hover', 'default'].includes(tpl)) {
                return
            }

            vm.svg_nodes[node_id].first().stroke({color: style[tpl].stroke_color}).fill(style[tpl].fill_color)

        }

        //Активировать блок. Отрисовка связи если есть
        Vue.prototype.$activateNodeBaseStructure = function (vm, node_id) {

            //Убираем активность с предыдущего блока
            vm.$deactivateNodeBaseStructure(vm)

            //Если есть точка соединения в другом элементе
            if (vm.active_elements.dot_node_id && vm.active_elements.dot_node_id !== node_id) {
                vm.$addNodsConnection(vm, vm.active_elements.dot_node_id, vm.active_elements.dot_index, node_id)

                vm.$deactivateConnectionDot(vm)
            } else {

							//replace
							//Устанавливаем активной
							this.$store.dispatch('setActiveNodeId', node_id)
						}

					//Применение стилей
					vm.$styleNodeBaseStructure(this, node_id, 'active')

        },

        //Деактивировать блок
        Vue.prototype.$deactivateNodeBaseStructure = function(vm){
            if(vm.active_node_id) {
                vm.$styleNodeBaseStructure(vm, vm.active_node_id, 'default', true)
            }

            //Обнуление параметра
            this.$store.dispatch('setActiveNodeId', null)
        }
        /* Каркас элемента */

        /* Линии и связи */
        //Получение параметров объекта линии - названии объекта и ключ
        Vue.prototype.$defineLineParams = function(vm, node_id, dot_index){

            let line_params = {
                el: null,
                key: null
            }

            //внутренняя линия
            if(dot_index) {
                line_params.el = 'svg_connection_sub_dots'
                line_params.key = node_id + '_' + dot_index
            }
            //внешняя линия
            else {
                line_params.el = 'svg_connection_dots'
                line_params.key = node_id
            }

            return line_params
        }
        //Создание коннекта
        Vue.prototype.$addNodsConnection = function(vm, dot_node_id, dot_index, to_node_id ){
            //Запись в стор связь елемента
            vm.addNodeConnectionToStore(dot_node_id, dot_index, to_node_id)
            vm.drawNodeConnections(dot_node_id, dot_index)
        }

        /* Линии и связи */

    }
}