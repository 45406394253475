<template>
    <svg
      :width="width"
      :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9628 11.3L18.693 9.03014L19.6829 8.04019L23.6427 12L19.6829 15.9598L18.693 14.9698L20.9628 12.7L14.7332 12.7L14.7332 11.3L20.9628 11.3ZM9.26682 12.7L3.03718 12.7L5.30703 14.9699L4.31708 15.9598L0.357281 12L4.31708 8.04025L5.30703 9.0302L3.03718 11.3L9.26682 11.3V12.7Z" :fill="iconColor"/>
    </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconLsSlider'
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>