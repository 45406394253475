/**
 * Created by Bezsmertnyi Ievgen on 12.09.2020.
 */

const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'RegisteredUsers';

let module_settings = {
  module_name,
};

//Корневой урл модуля
let module_alias = 'registered_users';

//Полный список разрешений модуля
module_settings.permits = {
  front: {
    //Отображение пунктов меню
    registered_users_show_menu_item: {
      description: 'Отображать в меню пункт зарегистрированных пользователей',
    },
    //Отображение табов
    registered_users_show_messengers_tab_item: {
      description:
        'Отображать таб мессенджеров зарегистрированных пользователей',
    },
  },

  back: {},
};

//Пункты в меню
module_settings.menu_items = [
  {
    title: getTranslate(
      'modules.register_users.module_settings.register_users'
    ),
    module: module_name,
    alias: module_alias,
    icon: 'mdi-card-account-details-outline',
    permits: {
      show: 'registered_users_show_menu_item',
    },
    show: true,
    route: 'registered_users',
  },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate(
        'modules.register_users.module_settings.register_users'
      ),
      content: '',
      show: true,
      type: 'registered_users',
      alias: '/',
      permits: { show: 'registered_users_show_messengers_tab_item' },
      route: 'users',
    },
  ],
};

//Подключенные компоненты
module_settings.components = [
  {
    name: module_name,
    path: 'Component/MainLayout.vue',
  },
];

//Экспорт для vue и для node
module.exports = { module_settings };
module.exports.default = { module_settings };
