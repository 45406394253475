<template>

    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 19.6 21.89">
        <defs>
            <symbol id="New_Symbol" data-name="New Symbol" viewBox="0 0 19.6 21.89">
                <path class="cls-1"
                      d="M9.31,19.6H2.1a.68.68,0,0,1-.49-.2.69.69,0,0,1-.21-.5V2.1a.65.65,0,0,1,.21-.49A.65.65,0,0,1,2.1,1.4H13L16.8,5.19v6.49a5.61,5.61,0,0,1,1.4,1v-8L18,4.4,13.8.2,13.59,0H2.1A2.06,2.06,0,0,0,.62.62,2.06,2.06,0,0,0,0,2.1V18.9a2.06,2.06,0,0,0,.62,1.48A2.06,2.06,0,0,0,2.1,21h8.37c-.09-.09-.2-.15-.3-.25A5.66,5.66,0,0,1,9.31,19.6Z"
                      :fill="iconColor"
                />
                <path class="cls-1" d="M15.4,8.34H2.8V6.94H15.4Z" :fill="iconColor"/>
                <path class="cls-1" d="M2.8,11.6h9a6,6,0,0,0-1.58,1.09c-.09.1-.16.21-.25.31H2.8Z" :fill="iconColor"/>
                <path class="cls-1"
                      :fill="iconColor"
                      d="M17.63,18.93a4.23,4.23,0,0,0,.78-2.43,4.2,4.2,0,1,0-4.2,4.2,4.23,4.23,0,0,0,2.43-.78l2,2,1-1Zm-1.45-.45a2.8,2.8,0,0,1-4.77-2,2.8,2.8,0,0,1,5.6,0A2.8,2.8,0,0,1,16.18,18.48Z"/>
            </symbol>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <use :width="width" :height="height" xlink:href="#New_Symbol"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconLsSearchlist'
    },
    width: {
      type: [Number, String],
      default: 19.6
    },
    height: {
      type: [Number, String],
      default: 21.89
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>