import axios from "../../axios-middleware";
import i18n from "@/lang/i18n.js";
import HELPERS from "@/modules/OperatorPanel/helpers";

const state = {
  operator_panel: {
    chatRoomClient: null,
    clientHistory: [],
    showHistoryItemId: '',
    showHistoryItemIdRight: '',

    clientStepsHistory: [],
    clientStepsHistoryCounter: 0,
    clientStepsHistoryShowMore: false,
  },
};

const mutations = {
  OP_SET_CHAT_ROOM_CLIENT(state, clientInfo) {
    state.operator_panel.chatRoomClient = clientInfo;
  },

  OP_SET_CLIENT_HISTORY(state, history) {
    state.operator_panel.clientHistory = history;
  },
  OP_SET_CLIENT_STEPS_HISTORY(state, history) {
    state.operator_panel.clientStepsHistory = [...history, ...state.operator_panel.clientStepsHistory]
  },
  OP_RESET_CLIENT_STEPS_HISTORY(state, history) {
    state.operator_panel.clientStepsHistory = [...history]
  },
  OP_CLEAR_CLIENT_STEPS_HISTORY(state) {
    state.operator_panel.clientStepsHistory = []
  },
  OP_SET_CLIENT_STEPS_HISTORY_COUNTER(state, length) {
    state.operator_panel.clientStepsHistoryCounter += length;
  },
  OP_CLEAR_CLIENT_STEPS_HISTORY_COUNTER(state) {
    state.operator_panel.clientStepsHistoryCounter = 0;
  },
  OP_SET_CLIENT_STEPS_HISTORY_SHOW_MORE(state, status) {
    state.operator_panel.clientStepsHistoryShowMore = status
  },

  OP_SET_SHOW_HISTORY_ITEM_ID (state, chatId) {
    state.operator_panel.showHistoryItemId = chatId
  },

  OP_SET_SHOW_HISTORY_ITEM_ID_RIGHT (state, chatId) {
    state.operator_panel.showHistoryItemIdRight = chatId
  },
};

const actions = {
  async opGetClientInfo({ commit }, clientId) {
    if (!clientId) {
      commit("OP_SET_CHAT_ROOM_CLIENT", null);
      return;
    }

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/client/${clientId}/info/`;

    const clientInfo = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.client || null;
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get') + " opGetClientInfo"]);
        return null;
      });

    commit("OP_SET_CHAT_ROOM_CLIENT", clientInfo);
  },

  async opGetClientHistory({ commit }, clientId) {
    if (!clientId) {
      commit("OP_SET_CHAT_ROOM_CLIENT", null);
      commit("OP_SET_CLIENT_HISTORY", []);
      return;
    }

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/client/${clientId}/chat_rooms_history/`;

    const history = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.chat_rooms_ids || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get') + " opGetClientHistory"]);
        return null;
      });

    commit("OP_SET_CLIENT_HISTORY", history);
  },

  async opGetHistoryItem(_context, chatRoomId) {
    if (!chatRoomId) return [];

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${chatRoomId}/messages/list?archive=1`;

    const chatRoomInfo = await axios
      .get(url)
      .then((response) => {
        const messages = response.data?.data?.messages || [];
        const participants = response.data?.data?.participants || [];

        return { messages, participants };
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return { messages: [], participants: [] };
      });

    return chatRoomInfo;
  },
  /**
   * Create client request
   * @param {Object} params - Request params
   * @returns {Boolean} - Success status
   */
  async opCreateClient(_context, params) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/create_update_reguser/`;

    const success = await axios({
      url,
      method: "POST",
      data: params,
    })
      .then((response) => {
        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t("common.error"),
          i18n.t("common.error_get"),
        ]);

        return false;
      });

    return success;
  },

  async opBlockClient(_context, params) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/ban_reguser/`;

    const success = await axios({
      url,
      method: "POST",
      data: params,
    })
      .then((response) => {
        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t("common.error"),
          i18n.t("common.error_get"),
        ]);

        return false;
      });

    return success;
  },

  opSetShowHistoryItemId({ commit }, chatId) {
    commit('OP_SET_SHOW_HISTORY_ITEM_ID', chatId)
  },

  opSetShowHistoryItemIdRight({ commit }, chatId) {
    commit('OP_SET_SHOW_HISTORY_ITEM_ID_RIGHT', chatId)
  },

  opCLearClientStepsHistory({commit, state}) {
    commit("OP_CLEAR_CLIENT_STEPS_HISTORY_COUNTER");
    commit("OP_SET_CLIENT_STEPS_HISTORY_SHOW_MORE", false);
    commit("OP_CLEAR_CLIENT_STEPS_HISTORY");
  },

  async opAxiosClientStepsHistory({ commit, state }, user_id= null) {
    let data = {};
    let limitElements = 50;

    if(user_id) {
      data = {
        user_id: user_id,
      }
    } else {
      if (
        !state.operator_panel?.chatRoomClient?.bot_id
        && !state.operator_panel.chatRoomClient.channels[0]?.kw_channel
        && !state.operator_panel.chatRoomClient.channels[0]?.kw_chat_id
      ) {
        // commit("OP_SET_CHAT_ROOM_CLIENT", null);
        commit("OP_SET_CLIENT_STEPS_HISTORY", []);
        commit("OP_SET_CLIENT_STEPS_HISTORY_COUNTER", 0);
        return;
      } else {
        data = {
          bot_id: state.operator_panel?.chatRoomClient?.bot_id,
          channel: state.operator_panel.chatRoomClient.channels[0]?.kw_channel,
          chat_id: state.operator_panel.chatRoomClient.channels[0]?.kw_chat_id,
        }
      }
    }


    const url = `${state.engine_settings.active_url}/kw/logs/list/`;

    const stepsHistory = await axios({
        url,
        method: "POST",
        data: data,
        params: {
          limit: limitElements,
          offset: state.operator_panel.clientStepsHistoryCounter,
        }
      })
      .then((response) => {
        return response.data?.data?.logs || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get') + " opGetClientHistory"]);
        return null;
      });



    let payloadLength = stepsHistory.length


    if (payloadLength < limitElements ) {
      commit("OP_SET_CLIENT_STEPS_HISTORY_SHOW_MORE", false);
    } else {
      commit("OP_SET_CLIENT_STEPS_HISTORY_SHOW_MORE", true);
    }

    commit("OP_SET_CLIENT_STEPS_HISTORY_COUNTER", payloadLength);
    commit("OP_SET_CLIENT_STEPS_HISTORY", stepsHistory.reverse());
  },

  async opAxiosResetClientStepsHistory({ commit, state }, user_id= null) {
    let data = {};
    let limitElements = 50;

    commit("OP_SET_CLIENT_STEPS_HISTORY", []);
    commit("OP_CLEAR_CLIENT_STEPS_HISTORY_COUNTER");


    if(user_id) {
      data = {
        user_id: user_id,
      }
    } else {
      if (
        !state.operator_panel?.chatRoomClient?.bot_id
        && !state.operator_panel.chatRoomClient?.channels[0]?.kw_channel
        && !state.operator_panel.chatRoomClient?.channels[0]?.kw_chat_id
      ) {
        return;
      } else {
        data = {
          bot_id: state.operator_panel?.chatRoomClient?.bot_id,
          channel: state.operator_panel.chatRoomClient.channels[0]?.kw_channel,
          chat_id: state.operator_panel.chatRoomClient.channels[0]?.kw_chat_id,
        }
      }
    }

    const url = `${state.engine_settings.active_url}/kw/logs/list/`;

    const stepsHistory = await axios({
      url,
      method: "POST",
      data: data,
      params: {
        limit: limitElements,
        offset: state.operator_panel.clientStepsHistoryCounter,
      }
    })
      .then((response) => {
        return response.data?.data?.logs || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get') + " opGetClientHistory"]);
        return null;
      });



    let payloadLength = stepsHistory.length

    if (payloadLength < limitElements ) {
      commit("OP_SET_CLIENT_STEPS_HISTORY_SHOW_MORE", false);
    } else {
      commit("OP_SET_CLIENT_STEPS_HISTORY_SHOW_MORE", true);
    }

    commit("OP_SET_CLIENT_STEPS_HISTORY_COUNTER", payloadLength);
    commit("OP_RESET_CLIENT_STEPS_HISTORY", stepsHistory.reverse());
  },

};

const getters = {
  opChatRoomClient: (state) => state.operator_panel.chatRoomClient,

  opClientHistory: (state, getters) => {
    const rawHistory = state.operator_panel.clientHistory;
    const getRoom = (item) => item.chat_room;

    // const history = rawHistory.map(getRoom);
    const history = rawHistory;

    const subjects = getters.opSubjectList;
    const users = getters.opAllUsersList;

    const options = { history, subjects, users };
    const normHistory = HELPERS.rebuildChatRooms(options);

    return normHistory;
  },

  opGetShowHistoryItemId: (state) => state.operator_panel.showHistoryItemId,
  opGetShowHistoryItemIdRight: (state) => state.operator_panel.showHistoryItemIdRight,
  opClientStepsHistory: (state) => state.operator_panel.clientStepsHistory,
  opClientStepsHistoryCounter: (state) => state.operator_panel.clientStepsHistoryCounter,
  opClientStepsHistoryShowMore: (state) => state.operator_panel.clientStepsHistoryShowMore,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
