import Vue from "vue";
import axios from "axios";
import i18n from "@/lang/i18n";

const state = {
  bot_store: {
    botSettings: {},
    botLanguages: [],
    defaultLanguageCode: null,
    modalAddOpen: false,
  },
};

const mutations = {
  SET_BOT_SETTINGS(state, payload) {
    // state.bot_store.botSettings = payload
    Vue.set(state.bot_store, 'botSettings', payload)
  },

  SET_BOT_LANGUAGES(state, payload) {
    Vue.set(state.bot_store, 'botLanguages', payload);
  },
  SET_DEFAULT_BOT_LANGUAGE(state, value) {
    Vue.set(state.bot_store, 'defaultLanguageCode', value);
  },

  SET_MODAL_ADD_OPEN(state, value) {
    state.bot_store.modalAddOpen = value
  },

};

const actions = {
  axiosGetBotSettingsById ({ state, commit, dispatch }, botId) {
    let token = Vue.$cookies.get('token_e');
    let url = state.engine_settings.active_url;
    let bot_id = parseInt(botId);

    if (url && token && bot_id) {
      dispatch('updateAjaxDialog', [true, true]);
      return axios
        .get(url + '/kw/bot/' + bot_id, {
          headers: {
            'X-CSRF-TOKEN': token,
          },
        })
        .then(
          (response) => {
            if (response.data && response.data.data.bot) {
              let botData = response.data.data.bot;

              commit('SET_BOT_SETTINGS', botData)
              dispatch('axiosGetBotLanguagesById', botId)
              dispatch('updateAjaxDialog', [ false, false ]);

              return botData
            }
          },
          (err) => {
						dispatch('updateAjaxDialogError', [
              err,
							i18n.t('common.error'),
							i18n.t(
                'modules.dialog.texts.settings.bot_data_not_loaded'
              ),
            ]);
          }
        );

    }
  },

	axiosGetWidgetUrlById ({ state } , botId) {
		return new Promise((resolve, reject) => {
			const token = Vue.$cookies.get('token_e');
			const bot_id = parseInt(botId);
			const url = state.engine_settings.active_url + '/kw/bot/' + bot_id;
			const config = {
				headers: { 'X-CSRF-TOKEN': token, }
			};

			axios.get(url, config)
				.then((response) => {
					resolve(response.data?.data?.bot?.widget_url)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},

  /**
   * @name axiosAddBotByObj
   * @description Add/Edit bot
   * @param bot_obj
   */
  axiosAddBotByObj( {state, dispatch}, bot_obj ) {
    let url = state.engine_settings.active_url;
    let request = bot_obj;
    let token = Vue.$cookies.get('token_e');

    if (request?.createdAt) delete request.createdAt
    if (request?.updatedAt) delete request.updatedAt
    if (request?.custom_json) delete request.custom_json

    request.json_data = JSON.stringify(request.json_data)

    dispatch('updateAjaxDialog', [true, true]);
    axios
      .post(url + '/kw/bot/', request, {
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
      .then(
        (response) => {
          dispatch('updateAjaxDialog', [ false, false, ]);
          if (
            response.data &&
            response.data.status &&
            response.data.status === 'success'
          ) {

            dispatch('axiosGetBotsAndLanguages');
            if (state.bot_store.modalAddOpen) dispatch('changeModalAddOpen', false);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [
            err,
            i18n.t('common.error'),
            i18n.t(
              'modules.dialog.texts.settings.cant_save_bot'
            ),
          ]);
        }
      );
  },

  async axiosGetBotLanguagesById({ commit, dispatch, state }, botId) {
    commit('SET_DEFAULT_BOT_LANGUAGE', null);

    let token = Vue.$cookies.get('token_e');
    let url = state.engine_settings.active_url;
    let bot_id = botId;

    if (url && token && state.engine_settings.active_id && bot_id) {
      await axios
        .get(url + '/kw/languages/' + bot_id + '/', {
          headers: {
            'X-CSRF-TOKEN': token,
          },
        })
        .then(
          (response) => {
            if (response?.data?.data?.languages) {

              let languages = response.data.data.languages;
              for (let lang of languages) {
                if (lang.default && lang.code) {
                  // commit('SET_BOT_SETTING', {
                  //   field: 'default_language_code',
                  //   value: lang.code,
                  // });
                  commit('SET_DEFAULT_BOT_LANGUAGE', lang.code);
                }
              }

              commit('SET_BOT_LANGUAGES', languages);
              dispatch('updateAjaxDialog', [false, false]);

            }else{
              dispatch('updateAjaxDialogError', [
                'Error',
                i18n.t('modules.dialog.texts.settings.error_language_list')
              ]);
            }

          },
          (err) => {
            dispatch('updateAjaxDialogError', [
              err,
              'Error',
              i18n.t('modules.dialog.texts.settings.error_language_list')
            ]);
          }
        );
    }
  },

  changeModalAddOpen({commit}, value) {
    commit("SET_MODAL_ADD_OPEN", value)
  },
};

const getters = {
  getBotSettings: (state) => state.bot_store.botSettings,
  getBotLanguages: (state) => state.bot_store.botLanguages,
  getDefaultBotLanguage: (state) => state.bot_store.defaultLanguageCode,

  getLanguagesObj: (state) => {
    let lang_obj = {};
    for (let lang of state.bot_store.botLanguages) {
      lang_obj[lang.code] = lang;
    }
    return lang_obj;
  },

  getModalAddOpen: (state) => state.bot_store.modalAddOpen,

}

export default {
  state,
  mutations,
  actions,
  getters,
};
