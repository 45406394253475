<template>
  <div>

    <h3 class="my-5">
      {{ $t("modules.customdata.statistics") }}
    </h3>

      <div
        v-for="(item, index) in statistics_charts"
        :key="index"
      >
        <v-card class="mb-5">
          <v-card-text>
            <v-sheet>
              <AppBarChart
                v-if="item.type === 'bar'"
                :statistic="setStatistics(item)"
                :showLegend="true"
                height="300"
              ></AppBarChart>
            </v-sheet>
          </v-card-text>
        </v-card>
      </div>
  </div>
</template>

<script>

import Vue from "vue";
import customDataMixin from '../views/customdata/customDataMixin';
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import i18n from "@/lang/i18n";
import BarChart from "./charts/bar";

export default  {
  name: 'DynamicStatistics',

  mixins: [customDataMixin],

  components: {
    AppBarChart: BarChart
	},

  props: {
    filters: {
      type: Object,
      default: () => {}
    },
    multiple_entity: {
      type: String,
      require: true
    },
    isShowed: {
      type: Boolean,
      default: false
    },

  },
  data: () => ({
    i18n,
    statistics_charts: null,
    color: [
        "#264653",
        "#2A9D8F",
        "#E9C46A",
        "#F4A261",
        "#E76F51",
        "#E63946",
        "#F1FAEE",
        "#A8DADC",
        "#457B9D",
        "#1D3557",
        "#FF5A5F",
        "#00A699",
        "#FC642D",
        "#484848",
        "#767676",
        "#CCDBE5",
        "#FFF3E5",
        "#EB7F00",
        "#004E66",
        "#8CBF99",
        "#7C3F58",
        "#E2711D",
        "#F6F4F3",
        "#393232",
        "#8D8686",
        "#F2CC8F",
        "#81B29A",
        "#E07A5F",
        "#3D405B",
        "#D62828",
        "#003049",
        "#D62828",
        "#F77F00",
        "#FCBF49",
        "#EAE2B7",
        "#14213D",
        "#FCA311",
        "#E5E5E5",
        "#9A8C98",
        "#F2A365",
        "#F58549",
        "#6D2E46",
        "#011627",
        "#FDFFFC",
        "#2EC4B6",
        "#E71D36",
        "#FF9F1C",
        "#011627",
        "#E71D36",
        "#2EC4B6",
        "#FF9F1C",
        "#0C7489",
        "#D90368",
        "#820263",
        "#291720",
        "#04A777",
        "#D54062",
        "#FF6B6B",
        "#4ECDC4",
        "#1A535C",
        "#F72585",
        "#7209B7",
        "#3F88C5",
        "#4CC9F0",
        "#90BE6D",
        "#F9C74F",
        "#F9844A",
        "#F8961E",
        "#577590",
        "#6D597A",
        "#D00000",
        "#FFBA08",
        "#3F37C9",
        "#6A0572",
        "#480CA8",
        "#B5179E",
        "#7209B7",
        "#560BAD",
        "#F72585",
        "#B5179E",
        "#7209B7",
        "#3F37C9",
        "#4CC9F0",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4895EF",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4895EF",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4895EF",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4895EF",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4895EF",
        "#4361EE",
        "#4895EF",
        "#4CC9F0",
        "#4361EE",
        "#4895EF"
      ]
  }),

  computed: {
    ...mapGetters([
      'engine_settings',
      'bot_settings',
      'custom_data_linked_tables_values'
    ]),

    
  },

  mounted() {
    this.getCustomEntityStatistics();
  },

  methods: {
    ...mapActions([
   
    ]),


     async getCustomEntityStatistics() {

      if(!this.isShowed) return;

      let data = null;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/statistic`;

      const params = {}
      //set filters
      if(Object.keys(this.filters).length !== 0) {
        params['filter'] = this.filters;
      }

      // this.$store.dispatch('updateAjaxDialog', [true, true]);
      this.loading = true


      await axios({
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': token,
        },
        url: url,
        params: {
          bot_id: bot_id,
        },
        data: params,
      })
        .then( (response) => {
          this.statistics_charts = response.data.data.statistics;
        },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'обновления' ]);
        }
      );

      return data;
    },

    setStatistics(item) {
      
      // let color = item?.color;
  
      let label = item?.label;
      let rows = item.data.filter((i) => i.value !== null);

      //Data for ChartBar
      return {
        labels: rows.map(
          (s) => {
            return s.label
          }),
        datasets: [
          {
            label: label,
            backgroundColor: rows.map(
              (_s, i) => this.color[i]
            ),
            data: rows.map((s) => {
              return this.roundToDecimal(s.value, 2)
            }),
          },
        ],
      };

    },

    /** Rounds a number to a specific decimal precision. */
    roundToDecimal(number, decimalPlaces) {
      const multiplier = Math.pow(10, decimalPlaces);
      return Math.round(number * multiplier) / multiplier || '';
    }

  },

  watch: {
    
  },

}
</script>

<style scoped lang="scss">

</style>
