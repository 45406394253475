<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.56663 11.7111L1.16546 11.31L1 11.4765V11.7111H1.56663ZM11.766 1.51174L12.1672 1.11056C12.1146 1.05779 12.052 1.01593 11.9832 0.987361C11.9144 0.958796 11.8406 0.944092 11.766 0.944092C11.6915 0.944092 11.6177 0.958796 11.5489 0.987361C11.48 1.01593 11.4175 1.05779 11.3648 1.11056L11.766 1.51174ZM17.4324 7.17807L17.8335 7.57924C17.8863 7.52661 17.9282 7.46408 17.9567 7.39524C17.9853 7.3264 18 7.2526 18 7.17807C18 7.10353 17.9853 7.02974 17.9567 6.96089C17.9282 6.89205 17.8863 6.82953 17.8335 6.77689L17.4324 7.17807ZM7.23296 17.3775V17.9441H7.46755L7.63414 17.7786L7.23296 17.3775ZM1.56663 17.3775H1C1 17.5277 1.0597 17.6719 1.16596 17.7781C1.27223 17.8844 1.41635 17.9441 1.56663 17.9441V17.3775ZM1.96781 12.1123L12.1672 1.91291L11.3648 1.11056L1.16546 11.31L1.96781 12.1123ZM11.3648 1.91291L17.0312 7.57924L17.8335 6.77689L12.1672 1.11056L11.3648 1.91291ZM17.0312 6.77689L6.83179 16.9763L7.63414 17.7786L17.8335 7.57924L17.0312 6.77689ZM7.23296 16.8108H1.56663V17.9441H7.23296V16.8108ZM2.13327 17.3775V11.7111H1V17.3775H2.13327ZM7.96505 5.31271L13.6314 10.979L14.4337 10.1767L8.7674 4.51036L7.96505 5.31271ZM10.0661 17.9441H17.999V16.8108H10.0661V17.9441Z"
              :fill="iconColor"
              :stroke="iconColor"
              stroke-width="0.25"/>
    </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconEdit'
            },
            width: {
                type: [Number, String],
                default: 19
            },
            height: {
                type: [Number, String],
                default: 19
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>