import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/Reviews/layout/Layout.vue';

export default [
  {
    path: '/reviews',
    component: LAYOUT.base,
    redirect: '/reviews/reviews',
    children: [
      {
        path: 'reviews',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'ReviewsReviews',
            component: () => import(/* webpackChunkName: 'reviews-reviews' */ '@/modules/Reviews/views/Reviews.vue'),
          },
        ],
      },
    ],
  },
];
