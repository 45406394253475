<template>
    <svg
      width="24"
      height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0888 9.86823H15.411V9.52299H15.0888V9.86823ZM15.0888 11.9383H14.7666C14.7666 12.0024 14.7832 12.0652 14.8147 12.1198C14.8462 12.1743 14.8912 12.2184 14.9447 12.2471C14.9982 12.2757 15.0581 12.2879 15.1177 12.2821C15.1773 12.2763 15.2343 12.2529 15.2821 12.2145L15.0888 11.9383ZM17.6666 9.86823V9.52299H17.5589L17.4732 9.59204L17.6666 9.86823ZM14.7666 9.86823V11.9383H15.411V9.86823H14.7666ZM15.2821 12.2145L17.8599 10.1444L17.4732 9.59204L14.8955 11.6621L15.2821 12.2145ZM17.6666 10.2135H21.5332V9.52299H17.6666V10.2135ZM21.5332 10.2135C22.0675 10.2135 22.4999 9.75085 22.4999 9.17775H21.8554C21.8554 9.36902 21.7117 9.52299 21.5332 9.52299V10.2135ZM22.4999 9.17775V2.96833H21.8554V9.17775H22.4999ZM22.4999 2.96833C22.4999 2.39523 22.0675 1.93262 21.5332 1.93262V2.62309C21.7117 2.62309 21.8554 2.77707 21.8554 2.96833H22.4999ZM21.5332 1.93262H13.7999V2.62309H21.5332V1.93262ZM13.7999 1.93262C13.2657 1.93262 12.8333 2.39523 12.8333 2.96833H13.4777C13.4777 2.77707 13.6214 2.62309 13.7999 2.62309V1.93262ZM12.8333 2.96833V9.17775H13.4777V2.96833H12.8333ZM12.8333 9.17775C12.8333 9.75085 13.2657 10.2135 13.7999 10.2135V9.52299C13.7576 9.52308 13.7156 9.51421 13.6765 9.4969C13.6374 9.47958 13.6018 9.45415 13.5719 9.42208C13.5419 9.39 13.5182 9.35191 13.502 9.30998C13.4859 9.26805 13.4776 9.22312 13.4777 9.17775H12.8333ZM13.7999 10.2135H15.0888V9.52299H13.7999V10.2135Z" :fill="iconColor"/>
        <path d="M15.411 9.86823H15.0888V9.52299M15.411 9.86823V9.52299H15.0888M15.411 9.86823V11.9383H14.7666M15.411 9.86823H14.7666V11.9383M15.0888 9.52299V10.2135H13.7999M15.0888 9.52299H13.7999M14.7666 11.9383H15.0888L15.2821 12.2145M14.7666 11.9383C14.7666 12.0024 14.7832 12.0652 14.8147 12.1198C14.8462 12.1743 14.8912 12.2184 14.9447 12.2471C14.9982 12.2757 15.0581 12.2879 15.1177 12.2821C15.1773 12.2763 15.2343 12.2529 15.2821 12.2145M15.2821 12.2145L17.8599 10.1444L17.4732 9.59204M15.2821 12.2145L14.8955 11.6621L17.4732 9.59204M17.6666 9.52299V9.86823L17.4732 9.59204M17.6666 9.52299H17.5589L17.4732 9.59204M17.6666 9.52299H21.5332M17.6666 9.52299V10.2135H21.5332M21.5332 10.2135V9.52299M21.5332 10.2135C22.0675 10.2135 22.4999 9.75085 22.4999 9.17775M21.5332 9.52299C21.7117 9.52299 21.8554 9.36902 21.8554 9.17775M22.4999 9.17775H21.8554M22.4999 9.17775V2.96833M21.8554 9.17775V2.96833M22.4999 2.96833H21.8554M22.4999 2.96833C22.4999 2.39523 22.0675 1.93262 21.5332 1.93262M21.8554 2.96833C21.8554 2.77707 21.7117 2.62309 21.5332 2.62309M21.5332 1.93262V2.62309M21.5332 1.93262H13.7999M21.5332 2.62309H13.7999M13.7999 1.93262V2.62309M13.7999 1.93262C13.2657 1.93262 12.8333 2.39523 12.8333 2.96833M13.7999 2.62309C13.6214 2.62309 13.4777 2.77707 13.4777 2.96833M12.8333 2.96833H13.4777M12.8333 2.96833V9.17775M13.4777 2.96833V9.17775M12.8333 9.17775H13.4777M12.8333 9.17775C12.8333 9.75085 13.2657 10.2135 13.7999 10.2135M13.4777 9.17775C13.4776 9.22312 13.4859 9.26805 13.502 9.30998C13.5182 9.35191 13.5419 9.39 13.5719 9.42208C13.6018 9.45415 13.6374 9.47958 13.6765 9.4969C13.7156 9.51421 13.7576 9.52308 13.7999 9.52299M13.7999 10.2135V9.52299" :stroke="iconColor" stroke-width="0.5"/>
        <path d="M10.5667 10.5079C10.5665 10.9543 10.4785 11.3962 10.3075 11.8085C10.1366 12.2208 9.88608 12.5954 9.57036 12.9109C9.25464 13.2265 8.87987 13.4767 8.46744 13.6474C8.05502 13.818 7.61301 13.9058 7.16667 13.9056C6.49443 13.9054 5.83736 13.7059 5.27855 13.3322C4.71973 12.9585 4.28427 12.4276 4.02722 11.8064C3.77018 11.1853 3.7031 10.5019 3.83446 9.84258C3.96583 9.18331 4.28974 8.5778 4.76524 8.10262C5.24074 7.62743 5.84647 7.30393 6.50583 7.173C7.16518 7.04207 7.84856 7.10961 8.46954 7.36707C9.09051 7.62453 9.6212 8.06035 9.99448 8.61941C10.3678 9.17848 10.5669 9.83568 10.5667 10.5079ZM12.8333 23.0117L1.5 22.9655V20.7034C1.5 19.8016 1.85821 18.9368 2.49584 18.2992C3.13346 17.6616 3.99826 17.3034 4.9 17.3034H9.43333C10.3351 17.3034 11.1999 17.6616 11.8375 18.2992C12.4751 18.9368 12.8333 19.8016 12.8333 20.7034V22.9666V23.0117Z" :stroke="iconColor" stroke-width="1.4" stroke-linecap="square"/>
    </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconLsInput'
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 25
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>