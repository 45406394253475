<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.60001 10.2593H4.86667V11.726H5.60001V10.2593ZM14.4 11.726H15.1333V10.2593H14.4V11.726ZM5.60001 14.6564H4.86667V16.1231H5.60001V14.6564ZM14.4 16.1333H15.1333V14.6667H14.4V16.1333ZM5.60001 5.86227H4.86667V7.32893H5.60001V5.86227ZM11.4667 7.33333H12.2V5.86667H11.4667V7.33333ZM14.4 0.733333L14.9192 0.214133L14.7036 0H14.4V0.733333ZM18.8 5.13333H19.5333V4.82973L19.3192 4.61413L18.8 5.13333ZM5.60001 11.726H14.4V10.2593H5.60001V11.726ZM5.60001 16.1231L14.4 16.1333V14.6667L5.60001 14.6564V16.1231ZM5.60001 7.32893L11.4667 7.33333V5.86667L5.60001 5.86227V7.32893ZM17.3333 20.5333H2.66667V22H17.3333V20.5333ZM1.93334 19.8V2.2H0.466675V19.8H1.93334ZM2.66667 1.46667H14.4V0H2.66667V1.46667ZM18.0667 5.13333V19.8H19.5333V5.13333H18.0667ZM13.8808 1.25253L18.2808 5.65253L19.3192 4.61413L14.9192 0.214133L13.8808 1.25253ZM2.66667 20.5333C2.47218 20.5333 2.28566 20.4561 2.14813 20.3185C2.0106 20.181 1.93334 19.9945 1.93334 19.8H0.466675C0.466675 20.3835 0.69846 20.9431 1.11104 21.3556C1.52362 21.7682 2.0832 22 2.66667 22V20.5333ZM17.3333 22C17.9168 22 18.4764 21.7682 18.889 21.3556C19.3016 20.9431 19.5333 20.3835 19.5333 19.8H18.0667C18.0667 19.9945 17.9894 20.181 17.8519 20.3185C17.7144 20.4561 17.5278 20.5333 17.3333 20.5333V22ZM1.93334 2.2C1.93334 2.00551 2.0106 1.81898 2.14813 1.68146C2.28566 1.54393 2.47218 1.46667 2.66667 1.46667V0C2.0832 0 1.52362 0.231785 1.11104 0.644365C0.69846 1.05695 0.466675 1.61652 0.466675 2.2H1.93334Z"
              :fill="iconColor"
        />
    </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconMenuBroadcast'
            },
            width: {
                type: [Number, String],
                default: 20
            },
            height: {
                type: [Number, String],
                default: 22
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>