<template>
    <svg
        :width="width"
        :height="height"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.93 21.92">
        <g>
            <path
                  d="M19.68,11.92a5.77,5.77,0,0,0,.1-1,5.77,5.77,0,0,0-.1-1l2-1.72a.69.69,0,0,0,.15-.88L19.56,3.56a.7.7,0,0,0-.82-.32l-2.65.87a8.71,8.71,0,0,0-1.62-.93L13.93.56A.7.7,0,0,0,13.25,0H8.68A.7.7,0,0,0,8,.57L7.47,3.18a8.48,8.48,0,0,0-1.63.93l-2-.68-.63-.19a.68.68,0,0,0-.81.32L.09,7.4a.72.72,0,0,0,.15.88l2,1.72a5.77,5.77,0,0,0-.1,1,5.77,5.77,0,0,0,.1,1l-2,1.72a.72.72,0,0,0-.15.88l2.28,3.84a.7.7,0,0,0,.82.32l2.66-.87a8.48,8.48,0,0,0,1.62.93L8,21.35a.7.7,0,0,0,.68.57h4.57a.69.69,0,0,0,.68-.56l.54-2.62a8.71,8.71,0,0,0,1.62-.93l2.65.87a.71.71,0,0,0,.82-.31l2.28-3.85a.69.69,0,0,0-.15-.88Zm-1.05,5.26-2.45-.82a.72.72,0,0,0-.66.12,7.31,7.31,0,0,1-1.92,1.1.71.71,0,0,0-.43.52l-.5,2.42H9.26l-.5-2.43a.71.71,0,0,0-.43-.51,7.21,7.21,0,0,1-1.93-1.1.69.69,0,0,0-.65-.12l-2.44.82L1.59,14.31l1.87-1.58a.7.7,0,0,0,.24-.65l0-.22a5.81,5.81,0,0,1-.11-.9,4.91,4.91,0,0,1,.11-.9l0-.22a.7.7,0,0,0-.24-.65L1.59,7.61,3.31,4.74l2.44.82a.72.72,0,0,0,.66-.12,7.48,7.48,0,0,1,1.92-1.1.71.71,0,0,0,.43-.51l.5-2.43h3.41l.5,2.43a.71.71,0,0,0,.43.51,7.31,7.31,0,0,1,1.92,1.1.72.72,0,0,0,.66.12l2.34-.79.11,0,1.71,2.87L18.47,9.19a.72.72,0,0,0-.23.65l0,.2a5,5,0,0,1,.11.92,5,5,0,0,1-.11.92l0,.2a.72.72,0,0,0,.23.65l1.87,1.58Z"
                  :fill="iconColor"
            />
            <path
                  d="M11,7.33a3.63,3.63,0,1,0,2.57,6.2A3.68,3.68,0,0,0,14.6,11,3.62,3.62,0,0,0,11,7.33Zm1.58,5.21a2.3,2.3,0,0,1-3.16,0A2.23,2.23,0,1,1,13.2,11,2.28,2.28,0,0,1,12.55,12.54Z"
                  :fill="iconColor"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconMenuCog'
            },
            width: {
                type: [Number, String],
                default: 22
            },
            height: {
                type: [Number, String],
                default: 22
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>