import cloneDeep from 'lodash/cloneDeep'
import {extra_modules_list} from '@/modules/custom_modules_list'

//Если есть настройки в модулях
let active_modules_settings = {}
if (extra_modules_list) {
    //Цикл по модулям
    for(let module of extra_modules_list){
        if(module && module.active && module.component_name){
            try{
                //Поиск файла настроек
                let obj = require('@/modules/' + module.component_name + '/kwizbot_settings.js')
                if(obj && obj.default && obj.default.module_settings){
                    //Получение объекта настроек
                    let module_settings = obj.default.module_settings

                    active_modules_settings[module.component_name] = module_settings
                }
            }catch(e){
                //console.log(e);
            }
        }
    }
}

export default {
    install(Vue) {

        //Extra Modules Settings
        //Манипуляции с данными в левой колонке из кастомных модулей
        Vue.prototype.$addExtraModulesSettingsToLeftSidebar = function (vm, extra_modules_settings) {

            //Если есть настройки в модулях
            if (extra_modules_settings) {

                //Цикл по модулям
                for (let module_name in extra_modules_settings) {

                    //Инициализация переменной, отвечающей за настройки left_sidebar
                    let module_left_sidebar

                    //Если есть настройки в модуле - присваиваем их переменной. Если нет - пропускаем итерацию цикла
                    if (extra_modules_settings[module_name] && extra_modules_settings[module_name].left_sidebar) {
                        module_left_sidebar = extra_modules_settings[module_name].left_sidebar
                    } else {
                        continue
                    }

                    //Если указаны элементы бокового меню - добавляем их к общим элементам
                    if (module_left_sidebar.sidebar_items) {
                        this.sidebar_items = this.sidebar_items.concat(module_left_sidebar.sidebar_items)
                    }
                }
            }

        }

        //Манипуляции с данными в svg блоке из кастомных модулей
        Vue.prototype.$addExtraModulesSettingsToSvgArea = function (vm, extra_modules_settings) {

            //Если есть настройки в модулях
            if (extra_modules_settings) {

                //Цикл по модулям
                for (let module_name in extra_modules_settings) {

                    //Инициализация переменной, отвечающей за настройки svg_area
                    let module_svg_area

                    //Если есть настройки в модуле - присваиваем их переменной. Если нет - пропускаем итерацию цикла
                    if (extra_modules_settings[module_name] && extra_modules_settings[module_name].svg_area) {
                        module_svg_area = extra_modules_settings[module_name].svg_area
                    } else {
                        continue
                    }

                    //Если указаны элементы бокового меню - добавляем их к общим элементам
                    if (module_svg_area.svg_icons_path) {
                        this.svg_icons_path = Object.assign(this.svg_icons_path, module_svg_area.svg_icons_path)
                    }
                }
            }

        }

        //Манипуляции с данными в меню из кастомных модулей
        Vue.prototype.$addExtraModulesSettingsToMainMenu = function () {

            //Если есть настройки в модулях
            //Цикл по модулям
            for(let module_folder in active_modules_settings){
                if(module_folder){
                    let module_settings = active_modules_settings[module_folder]

                    if (module_settings && module_settings.menu_items) {
                        //Если указаны элементы меню - добавляем их к общим элементам
                        this.full_menu_items = this.full_menu_items.concat(module_settings.menu_items)
                    } else {
                        continue
                    }
                }
            }

        }

        Vue.prototype.$defineNodeLangTexts = function (vm, node) {

            let all_lang_types_list = ['message', 'keyboard']
            if(! (node && node.id && node.type && all_lang_types_list.includes(node.type)) ){
                return false
            }

            if(node && node.id && vm.current_lang_code){
                let params = node.params
                let update_node = false

                //Определение идентификатора языка для текста. Если идентификатор не определен - создание нового

                if(['message', 'keyboard'].includes(node.type)) {
                    //Если нет перевода - создаем
                    if (params.text && !vm.current_translations_lang_obj[params.text]) {
                        vm.$store.dispatch('addOrUpdateNodeTranslate', {
                            lang_code: vm.current_lang_code,
                            translate_id: params.text,
                            text: ''
                        })
                    }
                }

                if(['keyboard'].includes(node.type)) {
                    //Определение языков в пунктах меню
                    if (node.next_elements_ids) {
                        for (let el of node.next_elements_ids) {

                            //Если нет перевода - создаем
                            if (!vm.current_translations_lang_obj[el.text]) {
                                vm.$store.dispatch('addOrUpdateNodeTranslate', {
                                    lang_code: vm.current_lang_code,
                                    translate_id: el.text,
                                    text: ''
                                })
                            }

                        }
                    }
                }

                //Если нода была обновлена - пишем в стор
                if(update_node) {
                    vm.$store.dispatch('updateNode', node)
                }
            }

        }

        Vue.prototype.$addKeyboardItem = function (vm, node) {

            if(! (node && node.id) ){
                return false
            }

            let element = cloneDeep(vm.default_node_keyboard_element)

            //Получение маскимального идентификатора перевода
            vm.$store.dispatch('incrementMaxId', 'translate')
            //присвоение идентификатора кнопке
            element.text = vm.current_max_translate_id

            node.next_elements_ids.push(element)
            vm.$store.dispatch('updateNode', node)

        }

        Vue.prototype.$removeKeyboardItem = function (vm, node, key) {

            if(! (node && node.id) ){
                return false
            }

            node.next_elements_ids.splice(key, 1);
            vm.$store.dispatch('updateNode', node)

        }

        Vue.prototype.$addActionItem = function (vm, node) {

            if(! (node && node.id) ){
                return false
            }

            let element = cloneDeep(vm.default_node_keyboard_element)

            node.next_elements_ids.push(element)
            vm.$store.dispatch('updateNode', node)

        }

        Vue.prototype.$defineSvgCursorCoordinates = function (vm, cursorX, cursorY) {

            let draw_viebox = vm.draw.attr().viewBox //Данные viebox
            let draw_viebox_dx = Math.floor(draw_viebox.split(" ")[0])      //смещение по горизонтали - это координата самой левой точки svg видимой на текущем экране
            let draw_viebox_dy = Math.floor(draw_viebox.split(" ")[1])      //смещение по вертикали - аналогично
            let draw_viebox_width = Math.floor(draw_viebox.split(" ")[2])   //ширина бокса
            let draw_viebox_height = Math.floor(draw_viebox.split(" ")[3])  //высота бокса

            let draw_client_width = vm.draw.node.clientWidth    //реальная ширина бокса без масштабирования
            let draw_client_height = vm.draw.node.clientHeight  //реальная высота бокса без масштабирования

            let draw_width_from_left = vm.draw.node.getBoundingClientRect().left //Расстояние от верхней границы окна до svg области
            let draw_height_from_top = vm.draw.node.getBoundingClientRect().top //Расстояние от верхней границы окна до svg области

            let dx_result = (cursorX - draw_width_from_left) * (draw_viebox_width/draw_client_width)       //смещение по горизонтали с учетом масштабирования и расположения svg элемента
            let dy_result = (cursorY - draw_height_from_top) * (draw_viebox_height/draw_client_height)     //смещение по вертикали с учетом масштабирования и расположения svg элемента

            //Определение начальных координат блоков
            let element_x_position = Math.floor(draw_viebox_dx + dx_result)
            let element_y_position = Math.floor(draw_viebox_dy + dy_result)

            return {
                x: element_x_position,
                y: element_y_position
            }

        }

    }
}