<template>
    <svg
      :width="width"
      :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8 2.20001H2.40005C2.02875 2.20001 1.67265 2.34751 1.4101 2.61006C1.14755 2.87261 1.00005 3.22871 1.00005 3.60001V20.4C1.00005 20.7713 1.14755 21.1274 1.4101 21.39C1.67265 21.6525 2.02875 21.8 2.40005 21.8H10.8" :stroke="iconColor" stroke-width="1.4"/>
        <path d="M22.5732 11.6001L16.9732 6.00006M22.5732 11.6001L16.9732 17.2001M22.5732 11.6001H9.55493" :stroke="iconColor" stroke-width="1.4"/>
        <circle cx="10.7164" cy="11.6001" r="1.21636" fill="white" :stroke="iconColor"/>
    </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconLsConnectFrom'
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>