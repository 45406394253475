<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.7333 19.8H2.19995V2.2H11.7333M19.8 11L15.4 15.7667L19.8 11ZM19.8 11L15.4 6.6L19.8 11ZM19.8 11H5.86662H19.8Z"
              :stroke="iconColor" stroke-width="1.6"/>
    </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconLogout'
            },
            width: {
                type: [Number, String],
                default: 18
            },
            height: {
                type: [Number, String],
                default: 17
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>