import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/Bids/layout/Layout.vue';

export default [
  {
    path: '/bids',
    component: LAYOUT.base,
    redirect: '/bids/section',
    children: [
      {
        path: 'section',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BidsSection',
            component: () => import(/* webpackChunkName: 'bids-section' */ '@/modules/Bids/views/SectionTab.vue'),
          },
        ],
      },
    ],
  },
];
