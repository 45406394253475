import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/CustomData/layout/Layout.vue';
import customDataTab from '@/modules/CustomData/views/customdata/CustomDataTab.vue';
import createTab from '@/modules/CustomData/views/customdata/CreateTab.vue';
import createGroup from '@/modules/CustomData/views/customdata/Groups.vue';
import importTab from '@/modules/CustomData/views/customdata/ImportTab.vue';
import OnBoardingScreen from '@/modules/CustomData/views/OnBoardingScreen.vue';

export default [
  {
    path: '/customdata/:id',
    component: LAYOUT.base,
		name: 'CustomData',
		redirect: '/customdata/:id',
    children: [
			{
				path: '/customdata/:id',
				component: moduleLayout,
				children: [
					{
						path: '',
						name: 'OnBoardingScreen',
						component: OnBoardingScreen,
					},
				],
			},
      {
        path: 'models/:model_name?',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'Models',
            component: customDataTab,
          },
        ],
      },
      {
        path: 'groups',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'Groups',
            component: createGroup,
          },
        ],
      },
      {
        path: 'create',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'Create',
            component: createTab,
          },
        ],
      },

        {
        path: 'import',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'Import',
            component: importTab,
          },
        ],
      },
    ],
  },
];
