<template>
  <div class="cd__image-slider-item">
    <img :src="imageUrl" @click="openImage" />
    <v-dialog v-model="dialog" :persistent="is_persistent" max-width="100%">
      <div class="cd__image-slider-item-big">
        <img :src="imageUrl" />
        <div class="cd__image-slider-item-big-close">
          <v-btn icon @click="closeImage">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

export default  {
  name: 'CdImageSliderItem',

  props: {
    imageUrl: {
      type: String
    },
    is_persistent: {
      type: Boolean,
      default: true,
    }
  },
  data: () => ({
    dialog: false,
  }),

  computed: {
    
  },

  mounted() {
    
  },

  methods: {
    openImage() {
      this.dialog = true;
    },
    closeImage() {
      this.dialog = false;
    },
  },
}
</script>

<style scoped lang="scss">
.cd__image-slider-item {
  height: calc(100% - 10px);
  width: auto;
  cursor: pointer;

  img {
    display: block;
    height: 100%;
    width: auto;
  }

  &-big {
    height: 90vh;
    background-color: #fff;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-close {
      background-color: #fff;
      border-radius: 100%;
      position: absolute;
      right: 24px;
      top: 24px;
      z-index: 3;
    }
  }
}
</style>
