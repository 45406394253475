<template>
  <svg :width="width" :height="height" viewBox="0 0 29 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.5117 7.53873L27.5115 7.53904C26.6183 9.42151 25.5129 10.8032 24.2086 11.7148C22.9086 12.6235 21.3823 13.0856 19.6081 13.0856C17.8349 13.0856 16.3086 12.6236 15.0083 11.715C13.7039 10.8036 12.598 9.42256 11.7049 7.54139C11.6628 7.45189 11.6409 7.35417 11.6409 7.25522C11.6409 7.15625 11.6628 7.0585 11.705 6.96899C12.5981 5.08676 13.7035 3.70523 15.0077 2.79364C16.3077 1.88495 17.8339 1.42285 19.6081 1.42285C21.3813 1.42285 22.9077 1.88494 24.208 2.79346C25.5121 3.70467 26.6178 5.08536 27.5108 6.96594C27.5977 7.15328 27.5959 7.36157 27.5117 7.53873ZM18.3459 8.43879C18.0317 8.12463 17.8552 7.69854 17.8552 7.25425C17.8552 6.80995 18.0317 6.38386 18.3459 6.0697C18.6601 5.75554 19.0862 5.57904 19.5305 5.57904C19.9747 5.57904 20.4008 5.75554 20.715 6.0697C21.0292 6.38386 21.2057 6.80995 21.2057 7.25425C21.2057 7.69854 21.0292 8.12463 20.715 8.43879C20.4008 8.75295 19.9747 8.92945 19.5305 8.92945C19.0862 8.92945 18.6601 8.75295 18.3459 8.43879ZM15.6123 7.25425C15.6123 9.41815 17.3665 11.1724 19.5305 11.1724C21.6944 11.1724 23.4486 9.41815 23.4486 7.25425C23.4486 5.09034 21.6944 3.33607 19.5305 3.33607C17.3665 3.33607 15.6123 5.09034 15.6123 7.25425Z" :fill="iconColor" :stroke="iconColor"/>
    <path d="M4.39697 6.63904H6.77588V7.66443H4.39697V10.3597H3.30713V7.66443H0.928223V6.63904H3.30713V4.1488H4.39697V6.63904Z" :fill="iconColor"/>
    <path d="M4.39697 6.63904H6.77588V7.66443H4.39697V10.3597H3.30713V7.66443H0.928223V6.63904H3.30713V4.1488H4.39697V6.63904Z" :stroke="iconColor"/>
  </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'IconHeadphonesPlus'
            },
            width: {
                type: [Number, String],
                default: 29
            },
            height: {
                type: [Number, String],
                default: 14
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>