<template>
  <svg
    width="22"
    height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2572 0L7.2655 7.89116L0 11.0204L7.2655 13.1973L10.2572 20L12.8215 13.1973L20.087 11.0204L12.8215 7.89116L10.2572 0Z" :fill="iconColor"/>
    <path d="M10.2572 0L7.2655 7.89116L0 11.0204L7.2655 13.1973L10.2572 20L12.8215 13.1973L20.087 11.0204L12.8215 7.89116L10.2572 0Z" :fill="iconColor"/>
    <path d="M18.2552 0.90918L17.1155 3.77869L14.3477 4.9166L17.1155 5.70819L18.2552 8.18191L19.232 5.70819L21.9998 4.9166L19.232 3.77869L18.2552 0.90918Z" :fill="iconColor"/>
  </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconCopilot'
            },
            width: {
                type: [Number, String],
                default: 22
            },
            height: {
                type: [Number, String],
                default: 20
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>