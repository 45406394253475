const axios = require('axios');

class UserService {
  _activeUserId;
  _engineSettings;
  _cookies;

  _compareMenu = {
    dialog: 'scenario',
    settings: 'settings',
    OperatorPanel: 'operatorPanel',
    operator_settings: 'operatorPanel.settings',
    BroadcastUsers: 'broadcastUsers',
    Broadcast: 'broadcastUsers.settings',
    broadcast: 'broadcastUsers.settings',
    FileManager: 'fileManager',
    Statistics: 'statistics',
    statistics: 'statistics',
    Statistics_settings: 'statistics.settings',
    RegisteredUsers: 'registeredUsers',
    Polls: 'polls',
    Bids: 'bids',
    Reviews: 'reviews',
    CustomData: 'custom_data',
    ActionJail: 'action_jail',
    FastLine: 'fast_line',
  };

  _compare = {
    BroadcastUsers: {},
    OperatorPanel: {},
    broadcast: {
      autobroadcast: 'auto',
      broadcast_logs: 'logs',
      broadcast: 'mailing',
      topics: 'subjects',
    },
    operator_settings: {
      skill_groups: 'skillGroups',
      quick_replies: 'fastAnswers',
      quickReplies: 'fastAnswers',
      quick_reply_categories: 'fastAnswersCategories',
    },
    FileManager: {},
    Statistics_settings: {},
    RegisteredUsers: {},
    Polls: {},
    Bids: {},
    Reviews: {},
    settings: {
      bot: 'botSettings',
      engines: 'servers',
    },
    dialog: {
      dialog: 'scenario',
      constants: 'constants',
    },
    ActionJail: {},
    FastLine: {},
  };
  _users = new Map();

  constructor(user) {
    if (user) {
      this.setUser(user);
    }
  }

  setData(user, engineSettings, cookies) {
    this._activeUserId = user.id;
    this._users.set(user.id, user);
    this._engineSettings = engineSettings;
    this._cookies = cookies;

    return this;
  }

  filterMenu(menu) {
    const filteredMenu = menu.map((m) => m.module);
    const permissions = this.getPermissions();

    if (!permissions) {
      return;
    }

    filteredMenu.forEach((item) => {
      if (this._compareMenu[item]) {
        const splitItem = this._compareMenu[item].split('.');

        if (splitItem.length === 2) {
          if (!permissions[splitItem[0]][splitItem[1]]?.route?.haveRights) {
            menu = menu.filter((m) => m.module !== item);
          }
        } else {
          if (!permissions[splitItem[0]]?.route?.haveRights) {
            menu = menu.filter((m) => m.module !== item);
          }
        }
      }
    });

    return menu;
  }

  filterTabsByExtention(extention, tabs) {
    if (extention) {
      extention = extention.toLowerCase();
    }

    const permissions = this.getPermissions();
    const splitItem = this._compareMenu[extention]?.split('.');

    if (!permissions || !splitItem) {
      return;
    }

    if (splitItem) {
      tabs.forEach((t) => {
        if (splitItem.length === 2) {
          const moduleSections =
            permissions[splitItem[0]][splitItem[1]]?.sections;

          if (moduleSections) {
            const modulType = this._compare[extention][t.type] || t.type;
            const modulePermission = moduleSections[modulType];
            const findModule = tabs.find((f) => f.type === t.type);

            if (findModule && modulePermission) {
              findModule.show = modulePermission.canShow;
            }
          }
        } else {
          const moduleSections = permissions[splitItem[0]]?.sections;

          if (moduleSections) {
            const modulType = this._compare[extention]?.[t.type] || t.type;
            const modulePermission = moduleSections[modulType];
            const findModule = tabs.find((f) => f.type === t.type);

            if (findModule && modulePermission) {
              findModule.show = modulePermission.canShow;
            }
          }
        }
      });
    }
  }

  filterTabs(tabs) {
    const permissions = this.getPermissions();

    for (const tab in tabs) {
      if (Object.hasOwnProperty.call(tabs, tab)) {
        const tabContent = tabs[tab];

        if (this._compareMenu[tab]) {
          const splitItem = this._compareMenu[tab].split('.');

          if (splitItem.length === 2) {
            tabContent.forEach((t) => {
              const moduleSections =
                permissions[splitItem[0]][splitItem[1]]?.sections;

              if (moduleSections) {
                const modulType = this._compare[tab][t.type] || t.type;
                const modulePermission = moduleSections[modulType];
                const findModule = tabs[tab].find((f) => f.type === t.type);

                if (findModule && modulePermission) {
                  findModule.show = modulePermission.canShow;
                }
              }
            });
          } else {
            tabContent.forEach((t) => {
              const moduleSections = permissions[splitItem[0]]?.sections;

              if (moduleSections) {
                const modulType = this._compare[tab][t.type] || t.type;
                const modulePermission = moduleSections[modulType];
                const findModule = tabs[tab].find((f) => f.type === t.type);

                if (findModule && modulePermission) {
                  findModule.show = modulePermission.canShow;
                }
              }
            });
          }
        }
      }
    }

    return tabs;
  }

  getChachedUser(userId) {
    return this._users.get(userId);
  }

  getCachedUsers() {
    return this._users.keys();
  }

  getPermissions() {
    return this.getChachedUser(this._activeUserId)?.role?.permissions;
  }

  isLoaded(userId) {
    return this._users.has(userId);
  }

  signOut() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_CONSTRUCTOR_URL + '/kw/sign-out/', {
          headers: {
            'X-CSRF-TOKEN': this._cookies.get('token_c'),
          },
          params: {
            bot_id: this._cookies.get('active_bot'),
          },
        })
        .then(
          (response) => {
            if (response?.data?.status === 'success') {
              resolve();
            }
          },
          () => {
            reject();
          }
        );
    });
  }
}

module.exports = UserService;
