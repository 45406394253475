<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.70225 5.1V5.66667H9.83559V5.1H8.70225ZM9.83559 5.08867V4.522H8.70225V5.08867H9.83559ZM9.83559 12.4667V7.93333H8.70225V12.4667H9.83559ZM9.83559 5.1V5.08867H8.70225V5.1H9.83559ZM7.56892 9.06667H9.26892V7.93333H7.56892V9.06667ZM7.56892 12.4667H10.9689V11.3333H7.56892V12.4667ZM9.26892 1.13333C10.2363 1.13333 11.1943 1.32388 12.088 1.69409C12.9818 2.0643 13.7939 2.60692 14.4779 3.29098C15.162 3.97504 15.7046 4.78713 16.0748 5.6809C16.445 6.57466 16.6356 7.5326 16.6356 8.5H17.7689C17.7689 6.24566 16.8734 4.08365 15.2793 2.48959C13.6853 0.895533 11.5233 0 9.26892 0V1.13333ZM1.90225 8.5C1.90225 6.54624 2.67838 4.6725 4.0599 3.29098C5.44142 1.90946 7.31516 1.13333 9.26892 1.13333V0C7.01458 0 4.85257 0.895533 3.25851 2.48959C1.66445 4.08365 0.768921 6.24566 0.768921 8.5H1.90225ZM9.26892 15.8667C7.31516 15.8667 5.44142 15.0905 4.0599 13.709C2.67838 12.3275 1.90225 10.4538 1.90225 8.5H0.768921C0.768921 10.7543 1.66445 12.9163 3.25851 14.5104C4.85257 16.1045 7.01458 17 9.26892 17V15.8667ZM9.26892 17C11.5233 17 13.6853 16.1045 15.2793 14.5104C16.8734 12.9163 17.7689 10.7543 17.7689 8.5H16.6356C16.6356 9.4674 16.445 10.4253 16.0748 11.3191C15.7046 12.2129 15.162 13.025 14.4779 13.709C13.7939 14.3931 12.9818 14.9357 12.088 15.3059C11.1943 15.6761 10.2363 15.8667 9.26892 15.8667V17Z"
              :fill="iconColor"/>
    </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconDelete'
            },
            width: {
                type: [Number, String],
                default: 18
            },
            height: {
                type: [Number, String],
                default: 17
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>