<template>
  <div>
    <v-navigation-drawer
      v-model="modal"
      class="cd_modal"
      absolute
      temporary
      right

      ref="drawerModalMultiple"
      :width="navigation.width"
    >
      {{/** MODAL CLOSE BTN **/}}
      <v-btn
        class="modal__close-btn"
        icon
        small
        absolute
        top
        left
        @click="modal = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      {{/** END MODAL CLOSE BTN **/}}

      <v-form ref="form" v-model="form_valid" class="settings-form">
        <v-card elevation="0">
          <v-card-title class="navigation-drawer__title">
            <template v-if="modal_create_mode">{{i18n.t('modules.customdata.entity.modal_title_clone_rows')}}</template>
            <template v-else>{{i18n.t('modules.customdata.entity.modal_title_edit_rows')}}</template>
          </v-card-title>


          <v-card-text v-if="modal" class="pb-2">

            <v-overlay
              absolute
              v-if="overlay"
              :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-container grid-list-md>
              <v-layout wrap>
                <template v-for="(field, key) in entity_fields">
                  <v-flex
                    xs12
                    :key="key"
                    class="settings-form__field"
                    :class="{
                      'settings-form__field--hidden': hiddenInCreatePopup(field)
                    }"
                  >
                    <v-select
                      class="my-4"
                      outlined
                      dense
                      :label="field.label || key"
                      :items="mapping_object_events"
                      :value="mapping_object_copy[key]"
                      @input="mapping_object_copy[key] = $event"
                      item-value="value"
                      item-text="text"
                      hide-details
                    />

                    <div
                      v-if="
                          mapping_object_copy[key] !== '__current__' &&
                          mapping_object_copy[key] !== '__default__'
                        "
                    >
                      {{/* print custom fields */}}
                      <template v-if="field.hasOwnProperty('association')">
                        <div>
                          <v-autocomplete
                            outlined
                            dense
                            :value="getItemObjValueForAssociation(key, field)"
                            @input="setItemObjValueForAssociation(key, $event)"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :item-text="(item) => item.label || item.name"
                            item-value="id"
                            :items="custom_data_linked_tables_values[key]"
                            return-object
                            :readonly="!modal_create_mode && !canSave"
                            @change="fetchOnChangeField(field, $event)"
                          />

                          <DynamicEntityFieldsStatic
                            v-if="
                                field.hasOwnProperty('fetch_on_create') &&
                                Object.keys(fetched_on_create_fields).length
                              "
                            :static_fields="fetched_on_create_fields"
                            :item_obj="mapping_object"
                          />
                        </div>
                      </template>

                      <template v-else>
                        <v-flex xs12>
                          {{/*STRING FIELDS*/}}
                          <template v-if="['STRING'].includes(field.type)">

                            <template v-if="field.string_input">
                              <date-picker
                                v-model.trim="mapping_object[key]"
                                :type="field.string_input.type"
                                :valueType="field.string_input.format"
                                :format="field.string_input.format"
                                :show-second="false"
                                :placeholder="returnFilterKeyOrLabel(key, field)"
                                :lang="$i18n.locale"
                                :editable="!modal_create_mode && !canSave"
                                :append-to-body="false"
                              />
                            </template>

                            <v-text-field
                              v-else
                              outlined
                              dense
                              v-model.trim="mapping_object[key]"
                              :label="returnFilterKeyOrLabel(key, field)"
                              :rules="field.rules"
                              :readonly="!modal_create_mode && !canSave"
                            ></v-text-field>

                          </template>


                          {{/*TEXTAREA FIELDS*/}}
                          <v-textarea
                            outlined
                            dense
                            v-if="['TEXT'].includes(field.type)"
                            v-model.trim="mapping_object[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="field.rules"
                            auto-grow
                            rows="2"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-textarea>

                          {{/*INTEGER FIELDS*/}}
                          <v-text-field
                            outlined
                            dense
                            v-if="['INTEGER', 'BIGINT'].includes(field.type)"
                            v-model.trim="mapping_object[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="[rules.onlyDigits]"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-text-field>

                          {{/*FLOAT FIELDS*/}}
                          <v-text-field
                            outlined
                            dense
                            v-if="['FLOAT'].includes(field.type)"
                            v-model.trim="mapping_object[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="[rules.floats]"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-text-field>

                          {{/*BOOL FIELDS*/}}
                          <v-checkbox
                            v-if="field.type === 'BOOLEAN'"
                            v-model="mapping_object[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            hide-details
                            :rules="field.rules"
                            class="mt-0 mb-4"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-checkbox>

                          {{/*DATE FIELDS*/}}
                          <v-menu
                            v-if="['DATE'].includes(field.type)"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!modal_create_mode && !canSave"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                dense
                                :value="formatDate(mapping_object[key], false, true)"
                                :label="returnFilterKeyOrLabel(key, field)"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="mapping_object[key]"
                              no-title
                              :locale="i18n.locale"
                              scrollable
                            >
                            </v-date-picker>
                          </v-menu>

                          {{/*FILE FIELDS*/}}
                          <div class="custom-data__file" v-if="field.file_upload && mapping_object.id">

                            <div class="custom-data__file--label">{{ returnFilterKeyOrLabel(key, field) }}</div>

                            <div class="d-flex align-center">
                              <div
                                class="d-flex align-center custom-data__file--item"
                                @click="openFileDialog($event)"
                              >

                                <template v-if="mapping_object[key] && mapping_object[key].files">
                                  <a
                                    :href="file.url"
                                    target="_blank"
                                    v-for="(file, fileIndex) in mapping_object[key].files"
                                    :key="fileIndex"
                                    class="file-item-link"
                                  >
                                    <v-img
                                      v-if="isImage(file.name)"
                                      height="40"
                                      width="40"
                                      :src="file.url"
                                    ></v-img>

                                    <v-icon v-else size="50" color="black3">mdi-file</v-icon>

                                    <v-icon
                                      size="14"
                                      color="primary"
                                      @click.prevent.stop="deleteFile(file.name, mapping_object.id, key)"
                                      class="file-item-del"
                                    >mdi-close-circle</v-icon>

                                  </a>
                                </template>
                              </div>

                              <v-btn
                                elevation="0"
                                height="36"
                                width="36"
                                min-width="auto"
                                text
                                class="custom-table__file--btn px-0 order-1 ml-2"
                                @click="openFileDialog($event)"
                              >
                                <v-icon size="20" color="primary">mdi-plus-circle-outline</v-icon>
                              </v-btn>

                              <v-file-input
                                multiple
                                v-model="uploadFiles"
                                accept="image/png, image/jpeg, image/jpg, .pdf, .doc, .docx, .xls, .xlsx"
                                :label="$t('modules.customdata.upload')"
                                v-on:change="mapping_object.id ? uploadFile(mapping_object.id, $event, key) : false"
                                class="fileInput"
                                style="display: none;"
                              ></v-file-input>

                            </div>
                          </div>

                          <template v-if="field.type_input">
                            {{field}}
                          </template>
                        </v-flex>
                      </template>

                    </div>

                  </v-flex>
                </template>
              </v-layout>
            </v-container>

          </v-card-text>

          <v-footer>
            <v-layout justify-center align-center>
              <div>{{i18n.t('modules.customdata.entity.mapping_object_events.count')}}: {{selected_entities.length}}</div>

              <v-btn
                color="primary"
                dark
                text
                @click="save()"
                :disabled="!modal_create_mode && !canSave"
                class="ml-4"
              >
                <template v-if="modal_create_mode">{{i18n.t('common.create')}}</template>
                <template v-else>{{i18n.t('common.save')}}</template>
              </v-btn>
            </v-layout>
          </v-footer>

        </v-card>
      </v-form>

    </v-navigation-drawer>
  </div>

</template>

<script>
import i18n from "@/lang/i18n";
import {mapGetters} from "vuex";
// import CdMap from "@/modules/CustomData/views/customdata/NewComponents/CdMap.vue";
// import CdImageSliderItem from "@/modules/CustomData/views/customdata/NewComponents/CdImageSliderItem.vue";
// import CdChart from "@/modules/CustomData/views/customdata/NewComponents/CdChart.vue";
// import CdImageSlider from "@/modules/CustomData/views/customdata/NewComponents/CdImageSlider.vue";
import DynamicEntityFieldsStatic from "@/modules/CustomData/components/DynamicEntityFieldsStatic.vue";
// import CdImageList from "@/modules/CustomData/views/customdata/NewComponents/CdImageList.vue";
// import CdChat from "@/modules/CustomData/views/customdata/NewComponents/CdChat.vue";
import Vue from "vue";
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/uk';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/de';

export default {
  name: "DynamicModalMultipleEntity",
  components: {
    DynamicEntityFieldsStatic,
    DatePicker,
  },

  props: {
    hidden_in_create_popup: {
      type: Boolean,
      default: false,
    },

    selected_entities: {
      type: Array,
      default: () => [],
    },

    value: {
      type: Boolean,
      default: false,
    },
    modal_create_mode: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: false,
    },


    entity_fields: {
      type: Object,
      default: () => {},
    },

    multiple_entity: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },

    custom_drawer_title: {
      type: String,
      default: ''
    },
  },

  data: function() {
    return {
      overlay: true,
      i18n,
      form_valid: true,

      //Panel resize
      navigation: {
        width: localStorage.getItem("drawerModalMultipleWidth") || '50%',
        borderSize: 8
      },
      //Panel resize

      create_association_row_dialog: false,

      fetched_on_create_fields: {},
      uploadFiles: [],

      mapping_ids: [],
      mapping_object: {},
      mapping_object_copy: {},

      mapping_object_events: [
        {
          value: '__current__',
          text: this.$i18n.t('modules.customdata.entity.mapping_object_events.current')
        },
        {
          value: '__default__',
          text: this.$i18n.t('modules.customdata.entity.mapping_object_events.default')
        },
        {
          value: 'edit',
          text: this.$i18n.t('modules.customdata.entity.mapping_object_events.edit')
        },
      ]
    };
  },


  async mounted() {
    this.mapping_ids = this.selected_entities
      .reduce((acc, curr) => {
        acc.push(curr.id)
        return acc
      }, [])

    this.mapping_object = JSON.parse(JSON.stringify(this.selected_entities))[0]
    this.mapping_object_copy = Object.keys(this.mapping_object).reduce((acc, curr) => {
      if(curr !== 'id' && curr !== 'createdAt' && curr !== 'updatedAt') {
        acc[curr] = '__current__'
      }
      return acc
    }, {})

    // //Panel resize
    await this.setBorderWidth();
    await this.setEvents();
    // //Panel resize

    setTimeout(() => {
      this.overlay = false
    }, 1000)
  },

  beforeDestroy: function () {
    this.setEvents(true); // Pass true to remove EventListeners
  },

  computed: {
    ...mapGetters([
      'get_custom_data_front_json',
      'custom_data_linked_tables_values',
      'rules',
      'engine_settings',
      'bot_settings'
    ]),

    modal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },

  methods: {
    //Panel resize
    setBorderWidth() {
      let i = this.$refs.drawerModalMultiple.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
    },

    setEvents(clean = false) {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawerModalMultiple.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = direction === "right"
          ? window.innerWidth - e.clientX
          : e.clientX;
        el.style.width = f + "px";
      }

      if (clean) {
        drawerBorder.removeEventListener("mousedown", this.handleMouseDown, false);
        document.removeEventListener("mouseup", this.handleMouseUp, false);
      } else {
        drawerBorder.addEventListener(
          "mousedown",
          function(e) {
            if (e.offsetX < minSize) {
              el.style.transition ='initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
        );

        document.addEventListener(
          "mouseup",
          function() {
            el.style.transition ='';

            if(window.innerWidth < 960){
              vm.navigation.width = parseInt(window.innerWidth);
            } else {
              vm.navigation.width = el.style.width;
            }

            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);

            localStorage.setItem("drawerModalMultipleWidth", vm.navigation.width);
          },
          false
        );
      }
    },
    //Panel resize end

    //fields
    hiddenInCreatePopup(field) {
      let hidden = false
      if (this.hidden_in_create_popup) {
        if (field?.hidden_in_create_popup) {
          // console.log(field, 'hiddenInCreatePopup')
          hidden = true
        }
      }
      return hidden
    },

    getItemObjValueForAssociation(key, field) {
      //auto select first on created mode
      if(field?.auto_select_first && this.modal_create_mode){
        this.setItemObjValueForAssociation(
          key,
          this.custom_data_linked_tables_values[key][0],
        )

        return this.mapping_object[key]
      }

      //TODO: need to optimization
      return  this.custom_data_linked_tables_values[key].find((i) => {
        if (i.name === this.mapping_object[key]){
          this.mapping_object[key] = JSON.parse(JSON.stringify(i))
          return i
        } else if (i.name === this.mapping_object[key]?.name) {
          return this.mapping_object[key]
        }
      })
    },

    async fetchOnChangeField(field, value) {

      //auto_select_associated_on_change
      if(field?.auto_select_associated_on_change?.length){
        for(const i in field?.auto_select_associated_on_change){
          if(
            this.custom_data_linked_tables_values[field.auto_select_associated_on_change[i]]
          ) {
            const val = this.custom_data_linked_tables_values[field.auto_select_associated_on_change[i]]
              .find((item) => item.is_id === value?.is_id)?.name

            this.setItemObjValueForAssociation(
              field.auto_select_associated_on_change[i],
              val || '',
            )
          }
        }
      }

      //fetch data and fill/show static fields
      if(
        field?.association?.model_name &&
        field?.fetch_on_create?.length &&
        value?.id
      ) {
        this.fetched_on_create_fields = {}

        const token = Vue.$cookies.get('token_e')
        const engine_url = this.engine_settings.active_url;
        const model_name = field?.association?.model_name
        const model_field = field?.association?.model_field
        const url = `${engine_url}/kw/custom_modules/${model_name}/list`
        const params = {
          limit: 1,
          offset: 0,
          order: {id: "desc"},
          filter: {[model_field]: {eq: value.id}}
        }

        await axios({
          method: 'POST',
          headers: {
            'X-CSRF-TOKEN': token,
          },
          url: url,
          params: {
            bot_id: this.$route?.params?.id,
          },
          data: params,
        })
          .then( (response) => {
              const columns = response.data.data.columns;
              const data = response.data.data.rows;

              if(data?.length) {
                for(const i in field?.fetch_on_create) {
                  const fieldName = field?.fetch_on_create[i]
                  //check if field exist in headers
                  const objFromHeader = this.headers?.find((el) => el.name === fieldName);

                  if(objFromHeader) {
                    Vue.set(
                      this.mapping_object,
                      fieldName,
                      data[0]?.[fieldName]
                    );

                    Vue.set(
                      this.fetched_on_create_fields,
                      fieldName,
                      {
                        label: columns.find( (el) => el.name === fieldName )?.label || fieldName,
                        subtype: columns.find( (el) => el.name === fieldName )?.subtype || 'STRING'
                      }
                    )
                  }
                }
              }
            },
            (err) => {
              console.log(err, 'err')
            }
          );
      }
    },
    findIndexWithAttr(array, attr, value) {
      for(let i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
          return i;
        }
      }
      return -1;
    },

    setItemObjValueForAssociation(key, value) {
      if(value) {
        this.mapping_object[key] = value
        this.mapping_object_copy[key] = 'edit'

      } else {
        delete this.mapping_object[key]
        delete this.mapping_object_copy[key]
      }
    },

    returnFilterKeyOrLabel(key, item) {
      return item?.label ? item?.label : key
    },

    checkNewComponent(key) {
      const newComponentsList = ['cd_map', 'cd_chart', 'cd_chat', 'cd_image_slider', 'cd_image_list']
      const matchResult = newComponentsList.some(newComponentsList => newComponentsList === key)
      return !matchResult
    },

    formatDate(isoTimeString, short = false, shortest = false) {
      if (!isoTimeString) return;
      const date = new Date(isoTimeString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      if (short) {
        return `${day}.${month}.${year.toString().slice(-2)} ${hours}:${minutes}`;
      } else if (shortest) {
        return `${day}.${month}.${year}`;
      } else {
        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      }
    },

    openFileDialog(event) {
      const previewLink = event.target.closest('a.file-item-link');
      if (!previewLink) {
        event.target.closest('.custom-data__file').querySelector('.fileInput label').click();
      }
    },

    /**
     * Upload file
     * @method uploadFile
     *
     * */
    async uploadFile(id, data, field_name, index) {

      if (data.length === 0) return false;

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/update_file/${id}`;
      const request = new FormData();

      let i = 0;
      for (let file of data) {
        request.set('files[' + i + ']', file, file.name);
        i++;
      }

      request.append('field_name', field_name);

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios.post(url, request, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "X-CSRF-TOKEN": token
        },
        params: {
          bot_id: bot_id,
        },
      })
        .then( async (response) => {
            this.$store.dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.uploaded') ]);

            if ( response?.data?.status === 'success' ) {
              this.mapping_object[field_name] = {files: []}
              const updatedFiles = response.data.data[field_name].files;
              this.mapping_object[field_name].files = updatedFiles;
              this.getItems();
              this.uploadFiles = [];
              success = true;
            }
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
          }
        );

      return success;
    },

    isImage(file) {
      const fileName = file.toLowerCase();
      return (fileName.endsWith('.jpg') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.png') ||
        fileName.endsWith('.gif') ||
        fileName.endsWith('.bmp') ||
        fileName.endsWith('.svg'));
    },

    entitySubtypeItems(entities, subtype) {
      const items = []

      if(this._hasObjectWithCustomValue(entities, 'subtype', subtype)) {
        for(let item in entities){
          if(
            entities[item]?.['subtype'] === subtype
            && this.item_obj[item]
          ) items.push(item)
        }
      }

      return items
    },
    _hasObjectWithCustomValue(obj, keyName, valueToCheck) {
      for (let key in obj) {
        if (
          obj.hasOwnProperty(key)
          && typeof obj[key] === 'object'
          && obj[key].hasOwnProperty(keyName)
          && obj[key][keyName] === valueToCheck
        ) return true
      }
      return false;
    },

    entityAssociatonAttributesSubtypeItems(subtype) {
      const items = []
      const attrKeys = this._returnAssociationAttributesKeys(subtype)
      if(!attrKeys?.length) return items

      for(let item in this.item_obj) {
        if(
          attrKeys.indexOf(item) !== -1
          && this.item_obj[item]
        ) items.push(item)
      }

      return items
    },
    _returnAssociationAttributesKeys(subtypeValue) {
      const keysFromAttributes = []
      if(!this.modal) return keysFromAttributes

      for (let item in this.entity_fields) {
        if(
          this.entity_fields[item]?.association?.attributes?.length
          && this.entity_fields[item]?.association?.subtype === subtypeValue
        ) {
          const attrArray = this.entity_fields[item].association.attributes
          for (let attr in attrArray) {
            if(
              keysFromAttributes.indexOf(attrArray[attr]) === -1
              && item !== attrArray[attr]
            ) {
              keysFromAttributes.push(attrArray[attr])
            }
          }
        }
      }

      return keysFromAttributes
    },

    async save() {
      this.overlay = true

      const request = this._.cloneDeep(this.mapping_object)

      for(let i in request) {
        const valueFromCopy = this.mapping_object_copy[i]

        if (typeof request === 'object') {
          request[i] = request[i]?.id || null
        }

        if(request[i] === null) {
          delete this.mapping_object_copy[i]
          delete request[i]
        }

        if (
          valueFromCopy === '__current__' ||
          valueFromCopy === '__default__'
        ) {
          request[i] = valueFromCopy
        }
      }

      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;

      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/clone/?bot_id=${bot_id}`

      const axiosRequest = {
        mapping_object: request,
        mapping_ids: this.mapping_ids,
      }

      await this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios
        .post(
          url,
          axiosRequest,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            },
            params: {
              mode: this.modal_create_mode ? 'clone' : 'edit',
            },
          }
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              success = true;
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_save'),
                ]);
              }
            } else {
              this.$store.dispatch('updateAjaxDialogError', [
                err,
                this.$t('common.error'),
                this.$t('common.error_save'),
              ]);
            }
          }
        );

      this.overlay = false
      this.modal = false
      this.$emit('save')

      return success;
    },
  }
}

</script>

<style lang="scss" scoped>
.cd_modal {
  z-index: 12;
}
.settings-form::v-deep {
  height: 100%;

  .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-card__text{
      padding-top: 5px;
      flex-grow: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;

      .v-data-table {
        flex: 1 1 auto;
        min-height: 0;
        display: flex;
        flex-direction: column;
      }
      .v-data-table__wrapper{
        flex: 1 1 auto;
        overflow-y: visible;
      }
    }
  }

  .mx-datepicker {
    width: 100%;
    margin-bottom: 20px;

    .mx-datepicker-main.mx-datepicker-popup {
      left: 0!important;
    }
    .mx-input {
      height: 44px;
      border-color: #9e9e9e;
    }
  }

  .settings-form__field {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    &--hidden {
      display: none !important;
    }
  }
}

.modal__close-btn {
  z-index: 1;
  top: 9px;
}

.modal__menu-btn {
  z-index: 1;
  top: 48px;

  &::before {
    opacity: 0.08;
  }
}

.v-navigation-drawer {
  &::v-deep .v-navigation-drawer__border {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.08) !important;

    &:after {
      content: '·······';
      color: #000;
      transform: rotate(90deg);
      position: absolute;
      top: 50%;
      left: -14px;
      font-size: 24px;
      line-height: 0.3;
      pointer-events: none;
    }

    @media screen and (max-width: 959px){
      display: none;
    }
  }
}
.navigation-drawer {
  &__title {
    font-size: 18px;
    margin-top: 30px;
    padding-left: 27px;
    padding-right: 50px;
    padding-bottom: 10px;
    text-align: left;
  }

  &__wrap {
    padding: 20px 15px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    margin-bottom: 25px;

    .cd__image-slider {
      display: flex !important;
      gap: 16px !important;
      height: 400px !important;
    }
  }

  &__item-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: left;
  }
}

</style>