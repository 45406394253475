
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    statistic: {
      type: Object,
      default: () => {}
    },
    tooltipLabel: {
      default: '',
    },
    showLegend: {
        type: Boolean,
        default: true

    },
    height: {
      type: String
    },
  },

  mounted() {
    this.render();
  },

  watch: {
    statistic: function(isGetted) {
      if (isGetted) {
        this.render();
      }
    },
  },

  methods: {
    render() {
      const tooltipCallback = {};

      if (this.tooltipLabel) {
        tooltipCallback["label"] = this.tooltipLabel;
      }

      this.renderChart(this.statistic, {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            ...tooltipCallback,
          },
        },
        legend: {
          display: this.showLegend
        }
      });
    },
  },
};
