<template>
    <svg
      :width="width"
      :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.2 18.4579L9.7824 18.0687C9.71838 17.973 9.63173 17.8945 9.53014 17.8403C9.42855 17.7861 9.31515 17.7578 9.2 17.7579V18.4579ZM12 22.6551L11.4176 23.0429C11.4815 23.1387 11.5681 23.2174 11.6697 23.2717C11.7713 23.3261 11.8848 23.3545 12 23.3545C12.1152 23.3545 12.2287 23.3261 12.3303 23.2717C12.4319 23.2174 12.5185 23.1387 12.5824 23.0429L12 22.6551ZM14.8 18.4579V17.7579C14.6848 17.7578 14.5715 17.7861 14.4699 17.8403C14.3683 17.8945 14.2816 17.973 14.2176 18.0687L14.8 18.4579ZM8.6176 18.8457L11.4176 23.0429L12.5824 22.2659L9.7824 18.0687L8.6176 18.8457ZM12.5824 23.0429L15.3824 18.8457L14.2176 18.0687L11.4176 22.2659L12.5824 23.0429ZM14.8 19.1579H20.4V17.7579H14.8V19.1579ZM20.4 19.1579C21.5606 19.1579 22.5 18.2199 22.5 17.0579H21.1C21.1 17.4457 20.7878 17.7579 20.4 17.7579V19.1579ZM22.5 17.0579V4.46768H21.1V17.0593H22.5V17.0579ZM22.5 4.46768C22.5 3.30568 21.5606 2.36768 20.4 2.36768V3.76768C20.7878 3.76768 21.1 4.07988 21.1 4.46768H22.5ZM20.4 2.36768H3.6V3.76768H20.4V2.36768ZM3.6 2.36768C2.4394 2.36768 1.5 3.30568 1.5 4.46768H2.9C2.9 4.07988 3.2122 3.76768 3.6 3.76768V2.36768ZM1.5 4.46768V17.0579H2.9V4.46768H1.5ZM1.5 17.0579C1.5 18.2199 2.4394 19.1579 3.6 19.1579V17.7579C3.50802 17.7581 3.41691 17.7401 3.3319 17.705C3.24689 17.6699 3.16965 17.6183 3.10461 17.5533C3.03958 17.4882 2.98802 17.411 2.95291 17.326C2.9178 17.241 2.89982 17.1499 2.9 17.0579H1.5ZM3.6 19.1579H9.2V17.7579H3.6V19.1579ZM8.5 13.5677H15.5V12.1677H8.5V13.5677ZM7.1 9.36768H16.9V7.96768H7.1V9.36768Z" :fill="iconColor"/>
    </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconLsMessage'
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 25
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>