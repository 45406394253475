import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/BroadcastUsers/layout/Layout.vue';

export default [
  {
    path: '/broadcast_users/:id',
    component: LAYOUT.base,
		name: 'BroadcastUsers',
    redirect: '/broadcast_users/:id/users',
    children: [
      {
        path: 'users',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BroadcastUsersUsers',
            component: () => import(/* webpackChunkName: 'broadcast-users-users' */ '@/modules/BroadcastUsers/views/BotUsers.vue'),
          },
        ],
      },
    ],
  },
];
