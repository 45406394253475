// import Vue from 'vue';
// import i18n from '@/lang/i18n';

export default {
  state: {
    authenticated: false,
    user: null,
    token: null,
    // rules: {
    //   required: (v) => (v && v !== '') || i18n.t('rules.required'),
    //   email: (v) => {
    //     const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return pattern.test(v) || i18n.t('rules.email');
    //   },
    // },
  },
  mutations: {
    setАuthenticated(state, payload) {
      state.authenticated = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  // actions: {
  //   loadData: ({ commit, dispatch, state }, configs) => {
  //     document.title = i18n.t('login.email_field');
  //   },
  // },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    token(state) {
      return state.token;
    },
    // user(state) {
    //   return state.user;
    // },
    // rules: (state) => {
    //   return state.rules;
    // },
  },
};
