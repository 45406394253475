<template>
  <v-container>
    <!--SELECT MODEL-->
    <v-row>
      <v-col>
       <v-select
              v-model="selectedTable"
              :items="custom_data_tables_select"
              item-text="name"
              item-value="name"
              :placeholder="$t('modules.customdata.select_model')"
              :hint="$t('modules.customdata.select_model')"
              persistent-hint
              outlined
              hide-details
              dense>
       </v-select>
        </v-col>
    </v-row>

    <!--IMPORT FILE-->
    <v-row>
      <v-col>
    <v-file-input v-if="selectedTable!==''"
        show-size
        truncate-length="15"
        v-model="file"

        :error-messages="file_loading_error"
        :accept="accept"
        :label="$t('modules.customdata.upload')"
        v-on:change="uploadFile"
    ></v-file-input>
        </v-col>

    </v-row>
    <!--COLUMN MATCH-->

     <v-row v-if="Object.keys(table).length > 0 && headers.length">
       <v-col>
      <v-card v-if="table.columns_json" class="pt-5">

        <div class="header pl-5 pr-5 d-flex" >
          <v-flex xs4  >{{$t('modules.customdata.model.column')}}</v-flex>
          <v-flex xs4  >{{$t('modules.customdata.model.xls_column')}}</v-flex>
          <v-flex xs4  >{{$t('modules.customdata.model.default_value')}}</v-flex>

        </div>
        <div class="pl-5 pr-5 d-flex" v-for="(field, i) in Object.keys(table.columns_json)" :key="i">

          <v-flex xs4 >
            <v-text-field
                disabled
                :label="field"
            ></v-text-field>
          </v-flex>

          <v-flex xs4  v-if="headers.length">
            <v-select
                v-model="option[i].header"
                :items="headers"
                solo
            ></v-select>
          </v-flex>

           <v-flex xs4 >
            <v-text-field
                v-model="default_values[i]"
                solo
            ></v-text-field>
          </v-flex>

         <!-- <div xs1 >
            <v-checkbox
                v-model="option[i].unique"
                :value="true"
            ></v-checkbox>
          </div> -->
        </div>
      </v-card>
         </v-col>
    </v-row>



    <div style="text-align: center">
      <v-btn
          :disabled="!file"
          color="primary"
          elevation="23"
          @click="importFromFile"
      >{{ $t('modules.customdata.import') }}</v-btn>
    </div>
  </v-container>
</template>

<script>


import {mapGetters, mapActions} from "vuex";
import Vue from "vue";
import axios from "axios";
import i18n from "@/lang/i18n";

export default {
  name: "ImportTab",

  data: () => ({
    file: null,
    file_preview: [],
    file_loading_error: '',
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xls, application/vnd.ms-excel, text/csv",
    table: {},
    tables: [],
    option: {},
    selectedTable:"",
    headers: [],
    // preview_limit: 3,
    default_values:[]
  }),

  async mounted() {
    await this.CustomDataAxiosGetTables(this.$route?.params?.id);
    this.tables = this.custom_data_tables
  },

  watch: {
    async selectedTable(t) {

      let tt_obj=null
      for(let tt of this.tables){
        if(tt.name === t){
          tt_obj=tt
          break;
        }
      }

      this.option = Object.keys(tt_obj.columns_json).map(item => {
        return {
          structure: item
        }
      })|| null;

      this.default_values=Array.apply(null, Array(Object.keys(tt_obj.columns_json).length)).map(function () {})
      this.table = tt_obj
    },
  },

  computed: {
    ...mapGetters([
      'dialog',
      'engine_settings',
      'bot_settings',
      'current_tab_type',
      'all_bot_data_init',
      'user',
      'custom_data_tables',
      'custom_data_tables_select'
    ]),

  },

  methods: {
    ...mapActions(['CustomDataAxiosGetTables']),

    /**
     * Upload and preview file
     * @method uploadFile
     *
     * */
    async uploadFile(data) {
      if (!data) return false;

      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/parse_file`

      const request = new FormData();
      request.append('file', data);
      request.append('bot_id', bot_id);

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios.post(url, request, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "X-CSRF-TOKEN": token
        },
      })
          .then( async (response) => {
                this.$store.dispatch('updateAjaxDialog', [false, false]);

                if ( response?.data?.status === 'success' ) {
                  // if (response.data?.data?.length && response.data?.data?.length > this.preview_limit) {
                  //   this.file_preview = response.data?.data.slice(0, this.preview_limit)
                  // } else {
                  //   this.file_preview = response.data?.data
                  // }
                  this.headers = response.data?.data
                  this.headers =[""].concat(this.headers)
                  success = true;
                }
              },
              (err) => {
                this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
              }
          );

      return success;
    },
    /**
     * Import data from file
     * @method importFromFile
     * @return {Promise<boolean>}
     */
    async importFromFile() {
      if (!this.file) return false;

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/import_data_from_file`
      const mapping_object = {}
      const default_map = {}

      this.option.forEach(o => {
        if(o?.header) {
          mapping_object[o.header] = o.structure
        } else {
          mapping_object[o.structure] = o.structure
        }
      })

      let i = 0;
      for(let d of this.default_values){

        if(d && d!=="")
          default_map[this.option[i].structure] = d
        i++;
      }


      const request = new FormData();
      request.append('file', this.file);
      request.append('bot_id', bot_id);
      request.append('module_name', this.table.name);
      request.append('mapping_object', JSON.stringify(mapping_object));
      request.append('default_map', JSON.stringify(default_map));

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios.post(url, request, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "X-CSRF-TOKEN": token
        },
      })
          .then( async (response) => {
                if ( response?.data?.status === 'success' ) {
                  success = true;
                   this.$store.dispatch('updateAjaxDialog',  [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.imported', {count:response?.data?.data?.inserted, total:response?.data?.data?.countFrom}) ]);
                }
              },
              (err) => {
                this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
              }
          );

      return success;
    }
  },
};
</script>




<style lang="scss">
.header {

  background-color: #b3e5fc;
  font-size: 12px;
  padding: 10px;
}
</style>
