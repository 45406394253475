<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.074 15.927a7.5 7.5 0 100-15 7.5 7.5 0 000 15z" :fill="iconColor"/>
    <path d="M11.333 4.26a.833.833 0 01.834.834v5a.834.834 0 01-.834.833H5.5l-1.667 1.667v-7.5a.833.833 0 01.834-.833h6.666zm-3.75 1.667v1.25h-1.25v.834h1.25v1.25h.834V8.01h1.25v-.834h-1.25v-1.25h-.834z" fill="#fff"/>
  </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconNewChat'
            },
            width: {
                type: [Number, String],
                default: 16
            },
            height: {
                type: [Number, String],
                default: 16
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>
