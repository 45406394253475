<template>
  <svg :width="width" :height="height" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4619 0.000976562C15.7746 0.0326828 14.1688 0.732278 12.9966 1.94632C11.8244 3.16037 11.1816 4.78975 11.2091 6.4771V12.5066H13.0849C13.2903 12.5138 13.4902 12.4394 13.6409 12.2997C13.7916 12.1599 13.8807 11.9661 13.8889 11.7607V8.78617C13.8807 8.58081 13.7916 8.38704 13.6409 8.24725C13.4902 8.10747 13.2903 8.03306 13.0849 8.0403H12.1024V6.4771C12.0748 5.02664 12.6235 3.62443 13.6282 2.5779C14.6329 1.53136 16.0115 0.925897 17.4619 0.894235C18.9123 0.925897 20.2909 1.53136 21.2956 2.5779C22.3003 3.62443 22.849 5.02664 22.8215 6.4771V8.0403H21.8389C21.6335 8.03306 21.4336 8.10747 21.2829 8.24725C21.1322 8.38704 21.0431 8.58081 21.0349 8.78617V11.7652C21.0442 11.9698 21.1339 12.1624 21.2844 12.3012C21.435 12.4401 21.6342 12.5139 21.8389 12.5066H23.7147V6.4771C23.7422 4.78975 23.0994 3.16037 21.9272 1.94632C20.755 0.732278 19.1492 0.0326828 17.4619 0.000976562Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0849 12.5066H11.2091V6.4771C11.1817 4.79582 11.8198 3.1721 12.984 1.95945C12.9882 1.95507 12.9924 1.95069 12.9966 1.94632C14.1643 0.736953 15.7623 0.0380792 17.4424 0.00137261C17.4489 0.00123069 17.4554 0.00109867 17.4619 0.000976562C17.4684 0.00109867 17.4749 0.00123069 17.4814 0.00137261C19.1616 0.0380792 20.7595 0.736953 21.9272 1.94632C21.9314 1.95069 21.9356 1.95507 21.9398 1.95945C23.104 3.1721 23.7421 4.79582 23.7147 6.4771V12.5066H21.8389C21.6342 12.5139 21.435 12.4401 21.2844 12.3012C21.1339 12.1624 21.0442 11.9698 21.0349 11.7652V8.78617C21.0431 8.58081 21.1322 8.38704 21.2829 8.24725C21.4293 8.11142 21.6222 8.03732 21.8215 8.03988C21.8273 8.03996 21.8331 8.0401 21.8389 8.0403H22.8215V6.4771C22.8216 6.47225 22.8216 6.4674 22.8217 6.46255C22.8453 5.01726 22.2969 3.62093 21.2956 2.5779C21.292 2.57413 21.2884 2.57037 21.2848 2.56662C20.2849 1.53104 18.9177 0.930448 17.4787 0.894628C17.4731 0.894488 17.4675 0.894357 17.4619 0.894235C17.4563 0.894357 17.4507 0.894488 17.4451 0.894628C16.0061 0.930448 14.6389 1.53104 13.6391 2.56662C13.6354 2.57037 13.6318 2.57413 13.6282 2.5779C12.6269 3.62093 12.0785 5.01726 12.1021 6.46255C12.1022 6.4674 12.1023 6.47225 12.1024 6.4771V8.0403H13.0849C13.0908 8.0401 13.0966 8.03996 13.1024 8.03988C13.3016 8.03732 13.4945 8.11142 13.6409 8.24725C13.7916 8.38704 13.8807 8.58081 13.8889 8.78617V11.7607C13.8807 11.9661 13.7916 12.1599 13.6409 12.2997C13.4902 12.4394 13.2903 12.5138 13.0849 12.5066ZM12.8889 9.0403H12.2091V11.5066M22.7147 9.0403H22.0349V11.5066H22.7147V9.0403Z" :fill="iconColor"/>
    <path d="M4.35645 5.63892H6.73535V6.66431H4.35645V9.35962H3.2666V6.66431H0.887695V5.63892H3.2666V3.14868H4.35645V5.63892Z" :fill="iconColor"/>
    <path d="M4.35645 5.63892H6.73535V6.66431H4.35645V9.35962H3.2666V6.66431H0.887695V5.63892H3.2666V3.14868H4.35645V5.63892Z" :stroke="iconColor"/>
  </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'IconHeadphonesPlus'
            },
            width: {
                type: [Number, String],
                default: 24
            },
            height: {
                type: [Number, String],
                default: 13
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>