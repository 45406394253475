<template>
  <div class="cd__chat">
    <div class="cd__chat-text">
      <vue-scroll>
        <v-card outlined  v-for="item in chatObject" :key="item.id">
          <strong class="cd__chat-text-name">{{ item.userName }}</strong>
          <span class="cd__chat-text-message">{{ item.message }}</span>
        </v-card>
      </vue-scroll>
    </div>
    <div class="cd__chat-input">
      <v-text-field
        outlined
        dense
        label="Введите сообщение"
        v-model="newMessage"
        hide-details
      ></v-text-field>
    </div>
    <div class="cd__chat-buttons">
      <v-btn
        elevation="0"
        color="primary"
        height="30"
        @click="sendMessage"
      >
        <span class="cd__actions-mobile-hide">Отправить</span>
      </v-btn>
      <v-btn
        elevation="0"
        color="primary"
        height="30"
        outlined
        disabled
      >
        <span class="cd__actions-mobile-hide">Решить спор</span>
      </v-btn>
    </div>
  </div>
</template>

<script>

export default  {
  name: 'CdChat',

  props: {
    chatObject: {
      type: Array
    }
  },
  data: () => ({
    newMessage: ''
  }),

  computed: {
    
  },

  mounted() {
    
  },

  methods: {
    sendMessage() {
      if (this.newMessage) {
        this.$emit('send-message', this.newMessage)
        this.newMessage = ''
      }  
    },

  },
}
</script>

<style scoped lang="scss">
.cd__chat {
  &-text {
    height: 200px;
    margin-bottom: 16px;
    background-color: #f5f6fa;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    text-align: left;

    &-name {
      display: block;
    }
  }
  &-input {
    margin-bottom: 16px;
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
  }

  &::v-deep .v-card {
    margin: 8px;
    padding: 8px;
  }
  &::v-deep .v-card + .v-card {
    margin-top: 8px;
  }
}
</style>
