<template>
  <div
    class="iframe-wrapper"
    :style="{ 'aspect-ratio': aspect_ratio }"
  >
    <iframe
      :src="src"
      loading="lazy"
      :title="title"
      allow="encrypted-media; fullscreen;"
      class="iframe"
      :style="iframe_styles"
    />
  </div>
</template>

<script>
  export default {
    name: 'IframeVideo',

    props: {
      aspect_ratio: {
        type: String,
        default: '1920/1080'
      },
      src: {
        type: String,
      },
      title: {
        type: String,
        default: 'Kwizbot intro'
      },
      iframe_styles: {
        type: String,
        default: ''
      },
    },
  }
</script>

<style scoped lang="scss">
  .iframe-wrapper {
    position: relative;
    overflow: hidden;
  }
  .iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    padding: 0;
    margin: 0;
    overflow:hidden;
  }
</style>