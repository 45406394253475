export default {
  data() {
    return {
      
    };
  },

  methods: {
    newComponentsMapChange(key) {
      const cdMapString = {
        longitude: this.item_obj[key + 'lon'],
        latitude: this.item_obj[key + 'lat']
      }
      this.item_obj[key] = JSON.stringify(cdMapString)
    },

    parseMapObject(str) {
      try {
        if (str && str.trim() !== '') {
          return JSON.parse(str)
        } else {
          return { longitude: 0, latitude: 0 }
        }
      } catch (error) {
        console.error('Error parsing JSON:', error)
        return { longitude: 0, latitude: 0 }
      }
    },

    sendChatMessage(key, value) {
      let chatObj = this.parseChatObject(this.item_obj[key]);

      if (!Array.isArray(chatObj)) {
        chatObj = [];
      }

			//TODO: change Name to dynamic
      const cdChatMessage = {
          id: Date.now(),
          userName: 'User Name',
          message: value
      };

      chatObj.push(cdChatMessage);

      this.item_obj[key] = JSON.stringify(chatObj);

      this.$nextTick(() => {
        this.scrollChatToBottom();
      });

    },
  

    parseChatObject(str) {
      try {
        if (str && str.trim() !== '') {
          return JSON.parse(str)
        } else {
          return []
        }
      } catch (error) {
        console.error('Error parsing JSON:', error)
        return []
      }
    },

    scrollChatToBottom() {
      const chatList = document.querySelector('.cd__chat-text .__panel');
      if (!chatList) {
        return;
      }
      setTimeout(() => {
        chatList.scrollTop = chatList.scrollHeight;
      }, 100);
    },

  },

  computed: {
    
  },

};
