/**
 * Created by Bezsmertnyi Ievgen on 12.09.2020.
 */

const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'FileManager';

let module_settings = {
  module_name,
};

//Корневой урл модуля
let module_alias = 'file_manager';

//Список разрешений модуля
module_settings.permits = {
  //Отображение пунктов меню
  filemanager_show_menu_item: {
    description: 'Отображать в меню пункт файлменеджера',
  },
  filemanager_show_tab_item: {
    description: 'Отображать таб файлменеджера',
  },
};

//Пункты в меню
module_settings.menu_items = [
  {
    title: getTranslate('modules.filemanager.module_settings.filemanager'),
    module: module_name,
    alias: module_alias,
    icon: '$iconmenufilemanager',
    permits: { show: 'filemanager_show_menu_item' },
    show: true,
    route: 'file_manager',
  },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate('modules.filemanager.module_settings.files'),
      content: '',
      show: true,
      type: 'files',
      alias: '/',
      permits: { show: 'filemanager_show_tab_item' },
      route: 'files',
    },
  ],
};

//Подключенные компоненты
module_settings.components = [
  {
    name: module_name,
    path: 'Component/tabs/FilesTab.vue',
  },
];

//Экспорт для vue и для node
module.exports = { module_settings };
module.exports.default = { module_settings };
