<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.77955 9.22045C7.13443 9.55802 7.13443 10.112 6.77955 10.4496C6.44198 10.7871 5.88802 10.7871 5.55045 10.4496C3.86259 8.7617 3.86259 6.01785 5.55045 4.33L8.61455 1.26589C10.3024 -0.421964 13.0463 -0.421964 14.7341 1.26589C16.422 2.95375 16.422 5.69759 14.7341 7.38545L13.4444 8.67514C13.4531 7.96538 13.3405 7.25561 13.0982 6.58047L13.505 6.165C14.5264 5.15229 14.5264 3.50771 13.505 2.495C12.4923 1.47363 10.8477 1.47363 9.835 2.495L6.77955 5.55045C5.75818 6.56316 5.75818 8.20774 6.77955 9.22045ZM9.22045 5.55045C9.55802 5.21288 10.112 5.21288 10.4496 5.55045C12.1374 7.2383 12.1374 9.98215 10.4496 11.67L7.38545 14.7341C5.69759 16.422 2.95375 16.422 1.26589 14.7341C-0.421964 13.0463 -0.421964 10.3024 1.26589 8.61455L2.55559 7.32486C2.54693 8.03462 2.65945 8.74439 2.90181 9.42818L2.495 9.835C1.47363 10.8477 1.47363 12.4923 2.495 13.505C3.50771 14.5264 5.15229 14.5264 6.165 13.505L9.22045 10.4496C10.2418 9.43684 10.2418 7.79226 9.22045 6.77955C8.86557 6.44198 8.86557 5.88802 9.22045 5.55045Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconCopyLink",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
