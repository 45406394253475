<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.7932 4.35355C18.9885 4.15829 18.9885 3.84171 18.7932 3.64645L15.6113 0.464466C15.416 0.269204 15.0994 0.269204 14.9042 0.464466C14.7089 0.659728 14.7089 0.976311 14.9042 1.17157L17.7326 4L14.9042 6.82843C14.7089 7.02369 14.7089 7.34027 14.9042 7.53553C15.0994 7.7308 15.416 7.7308 15.6113 7.53553L18.7932 4.35355ZM0 4.5L18.4397 4.5V3.5L0 3.5L0 4.5Z"
              :fill="iconColor"
        />
    </svg>


</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconArrowRightLong'
            },
            width: {
                type: [Number, String],
                default: 19
            },
            height: {
                type: [Number, String],
                default: 8
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>