import Vue from 'vue';
import axios from 'axios/index';
import { mapGetters } from 'vuex';
import i18n from '@/lang/i18n';

export default {
  data() {
    return {
      customdata: {},
    };
  },

  computed: {
    ...mapGetters(['current_tab_type', 'engine_settings', 'bot_settings']),

    // Grouped items for select with models
    groupNamesById() {
      return Object.fromEntries(
        this.get_custom_data_groups.map(item => [item.id, item.name])
      );
    },

    custom_data_tables_grouped() {
      return this.sortGroupIdArray()
        .reduce((result, groupID) => {
          const groupName = this.groupNamesById[groupID] || i18n.t('modules.customdata.groups.withoutGroup');
          result.push({ header: groupName });

          const objectsWithGroupID = this.custom_data_tables.filter(obj => obj.group_id === groupID);
          result.push(...objectsWithGroupID);

          return result;
        }, []);
    },
  },

  methods: {
    checkNewComponent(field) {
      console.log(field)
    },

		sortGroupIdArray() {
			let array = [...new Set(this.custom_data_tables.map(obj => obj.group_id))]
			let objects = this.get_custom_data_groups

			// Create a lookup table for priorities
			const priorityLookup = objects.reduce((acc, obj) => {
				acc[obj.id] = obj.priority;
				return acc;
			}, {});

			// Function to get priority, assigning a large number for null to ensure it sorts last
			const getPriority = (id) => id === null ? Infinity : priorityLookup[id];

			// Sort the array based on priority
			return array.sort((a, b) => getPriority(a) - getPriority(b));
		}
  },

  mounted() {
    const url = this.engine_settings.active_url;
    const token = Vue.$cookies.get('token_e');
    const bot_id = parseInt(this.bot_settings.active_id);
    const request = {
      bot_id,
    };

  },
};
