<template>
  <v-card
    class="mb-6"
    outlined
  >
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <template
            v-for="(item, key) in static_fields"
          >
            <tr
              :key="key"
              v-if="item.subtype === 'STRING'"
            >
              <td align="start">
                {{ item.label || key }}
              </td>
              <td align="start">
                {{ item_obj[key] }}
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <div v-if="Object.keys(filterBySubtype(static_fields, 'show_image_in_slider')).length">
      <template
        v-for="(item, key) in filterBySubtype(static_fields, 'show_image_in_slider')"
      >
        <div
          class="px-4 pb-2 mt-2"
          :key="key"
        >
          <div
            class="text-left font-weight-bold text-body-2 mb-1"
          >{{ item.label || key }}</div>
          <v-sheet
            height="150"
            max-height="150"
          >
            <CdImageSliderItem
              :imageUrl="item_obj[key]"
              :key="key"
              :is_persistent="false"
            />
          </v-sheet>

        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
import CdImageSliderItem from "@/modules/CustomData/views/customdata/NewComponents/CdImageSliderItem.vue";

export default {
  name: "DynamicFieldsEntityStatic",

  components: {
    CdImageSliderItem
  },

  props: {
    static_fields: {
      type: Object,
      default: () => {},
    },
    item_obj: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    filterBySubtype(data, subtypeValue) {
      let filtered = {};
      for (let key in data) {
        if (data[key].subtype === subtypeValue) {
          filtered[key] = data[key];
        }
      }
      return filtered;
    }
  },

}
</script>