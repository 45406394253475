<template>
    <router-view v-if="have_permission"></router-view>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data: () => ({
    settings_user_permission: {
      haveRights: null,
      sections: {},
    },
    have_permission: true,
  }),

  created: function () {
    this.settings_user_permission.haveRights = this.user?.role?.permissions?.operatorPanel?.settings?.route?.haveRights
    this.settings_user_permission.sections = this.user?.role?.permissions?.operatorPanel?.settings?.sections

    this.checkUserPermission()
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
  },

  watch:{
    $route (){
      this.checkUserPermission()
    },
  },

  methods: {
    checkUserPermission() {
      const currentTab = this.$route?.name;
      const tabPermissions = this.settings_user_permission.sections[currentTab];

      if (!tabPermissions || !tabPermissions.canShow) {
        for (const tabName in this.settings_user_permission.sections) {
          if (this.settings_user_permission.sections[tabName].canShow) {
            this.$router.push({ name: tabName });
            return;
          }
        }
      }

      this.have_permission = this.settings_user_permission.haveRights && tabPermissions?.canShow;
    }
  }
}
</script>
