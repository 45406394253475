<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.04464 4.30129C0.849381 4.49655 0.84938 4.81314 1.04464 5.0084L4.22662 8.19038C4.42188 8.38564 4.73847 8.38564 4.93373 8.19038C5.12899 7.99512 5.12899 7.67853 4.93373 7.48327L2.1053 4.65484L4.93373 1.82642C5.12899 1.63116 5.12899 1.31457 4.93373 1.11931C4.73847 0.924049 4.42188 0.924049 4.22662 1.11931L1.04464 4.30129ZM19.8379 4.15485L1.3982 4.15484L1.3982 5.15484L19.8379 5.15485L19.8379 4.15485Z"
              :fill="iconColor"
        />
    </svg>


</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconArrowLeftLong'
            },
            width: {
                type: [Number, String],
                default: 20
            },
            height: {
                type: [Number, String],
                default: 9
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>