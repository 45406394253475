import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// import colors from 'vuetify/lib/util/colors';

import IconEdit from '@/components/icons/IconEdit.vue'
import IconDelete from '@/components/icons/IconDelete.vue'
import IconInformation from '@/components/icons/IconInformation.vue'
import IconLsMessage from '@/components/icons/IconLsMessage.vue'
import IconLsInput from '@/components/icons/IconLsInput.vue'
import IconLsKeyboard from '@/components/icons/IconLsKeyboard.vue'
import IconLsAction from '@/components/icons/IconLsAction.vue'
import IconLsSearchlist from '@/components/icons/IconLsSearchlist.vue'
import IconLsSlider from '@/components/icons/IconLsSlider.vue'
import IconLsConnectFrom from '@/components/icons/IconLsConnectFrom.vue'
import IconLsConnecTo from '@/components/icons/IconLsConnecTo.vue'
import IconSave from '@/components/icons/IconSave.vue'
import IconLogout from '@/components/icons/IconLogout.vue'
import IconMenuCog from '@/components/icons/IconMenuCog.vue'
import IconMenuDialog from '@/components/icons/IconMenuDialog.vue'
import IconMenuOp from '@/components/icons/IconMenuOp.vue'
import IconMenuOpSettings from '@/components/icons/IconMenuOpSettings.vue'
import IconMenuBroadcast from '@/components/icons/IconMenuBroadcast.vue'
import IconMenuFilemanager from '@/components/icons/IconMenuFilemanager.vue'
import IconUserInputTollbox from '@/components/icons/IconUserInputTollbox.vue'
import IconArrowRightLong from '@/components/icons/IconArrowRightLong.vue'
import IconArrowLeftLong from '@/components/icons/IconArrowLeftLong.vue'
import IconLogin from '@/components/icons/IconLogin.vue'
import IconPasswd from '@/components/icons/IconPasswd.vue'
import IconEye from '@/components/icons/IconEye.vue'
import IconEyeNo from '@/components/icons/IconEyeNo.vue'
import IconNewChat from '@/components/icons/IconNewChat.vue'
import IconArrowRightShort from '@/components/icons/IconArrowRightShort.vue'
import IconExportXls from '@/components/icons/IconExportXls.vue'
import IconCopyLink from "@/components/icons/IconCopyLink.vue";
import IconSort from "@/components/icons/IconSort.vue";
import IconTableEdit from "@/components/icons/IconTableEdit.vue";
import IconTelegram from "@/components/icons/IconTelegram.vue";
import IconHeadphonesPlus from "@/components/icons/IconHeadphonesPlus.vue";
import IconEyePlus from "@/components/icons/IconEyePlus.vue";
import IconAddChatUser from "@/components/icons/IconAddChatUser.vue"
import IconAiButton from '@/components/icons/IconAiButton.vue';
import IconFileVideo from "@/components/icons/IconFileVideo.vue";
import IconFiles from "@/components/icons/IconFiles.vue";
import IconCopilot from "@/components/icons/IconCopilot.vue";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#5567FF',
                primaryBrand: '#44546f',
                primaryLighten: '#5784eb',
                primaryLight: '#D6DCFA',
                primaryLighten1: '#8794FF',
                primaryBackground: '#f5f6fa',
                primaryButtonBg: '#C2D2F8',
                primaryBlue: '#5567FF',
                primaryBlueDark: '#537cdb',
                secondary: '#050038', // #FFCDD2
                gray: '#B0BEC8', // #FFCDD2
                gray1: '#AFAFAF',
                gray2: "#f8f8f8",
                gray4: '#F8F8F8',
                gray5: '#e4e4e4',
                blueHaze: '#d0d4e4',
                athensGray: '#F5F6F8',
                ghost: '#c3c6d4',
                accent: '#767396', // #3F51B5
                greyBlueLight: '#AEAEC5',
                greyPurple: '#6C6C8B',
                red: '#F51C5D',
                black: '#000',
                black2: '#292931',
                black3: '#484848',
                orangeLight1: "#fff0de",
								opBorderGray: '#e4e4e4',
								opBgGray: '#f2f2f2',
								opBgGray2: '#f9f9f9',

            },
        },
    },
    customVariables: ['../scss/variables.scss'],
    icons: {
        values: {
            iconedit: { // name of our custom icon
                component: IconEdit, // our custom component
            },
            icondelete: {
                component: IconDelete,
            },
            iconinformation: {
                component: IconInformation,
            },
            iconlsmessage: {
                component: IconLsMessage,
            },
            iconlsinput: {
                component: IconLsInput,
            },
            iconlskeyboard: {
                component: IconLsKeyboard,
            },
            iconlsaction: {
                component: IconLsAction,
            },
            iconlssearchlist: {
                component: IconLsSearchlist,
            },
            iconlsslider: {
                component: IconLsSlider,
            },
            iconlsconnectfrom: {
                component: IconLsConnectFrom,
            },
            iconlsconnecto: {
                component: IconLsConnecTo,
            },
            iconsave: {
                component: IconSave,
            },
            iconlogout: {
                component: IconLogout,
            },
            iconmenucog: {
                component: IconMenuCog,
            },
            iconmenudialog: {
                component: IconMenuDialog,
            },
            iconmenuop: {
                component: IconMenuOp,
            },
            iconmenuopsettings: {
                component: IconMenuOpSettings,
            },
            iconmenubroadcast: {
                component: IconMenuBroadcast,
            },
            iconmenufilemanager: {
                component: IconMenuFilemanager,
            },
            iconUserInputTollbox: {
                component: IconUserInputTollbox,
            },
            iconArrowRightLong: {
                component: IconArrowRightLong,
            },
            iconArrowLeftLong: {
                component: IconArrowLeftLong,
            },
            iconAiButton: {
                component: IconAiButton,
            },
            iconlogin: {
                component: IconLogin,
            },
            iconpasswd: {
                component: IconPasswd,
            },
            iconeyeno: {
                component: IconEyeNo,
            },
            iconeye: {
                component: IconEye,
            },
            iconnewchat: {
                component: IconNewChat,
            },
            iconarrowrightshort: {
                component: IconArrowRightShort,
            },
            iconexportxls: {
                component: IconExportXls,
            },
            iconcopylink: {
                component: IconCopyLink,
            },
            iconsort: {
                component: IconSort,
            },
            icontableedit: {
                component: IconTableEdit,
            },
            icontelegram: {
                component: IconTelegram,
            },
						iconheadphonesplus: {
                component: IconHeadphonesPlus,
            },
						iconeyeplus: {
                component: IconEyePlus,
            },
            iconAddChatUser: {
                component: IconAddChatUser,
            },
						iconFileVideo: {
                component: IconFileVideo,
            },
						iconFiles: {
							component: IconFiles,
						},
						iconCopilot: {
                component: IconCopilot,
            },
        },
    },
});
