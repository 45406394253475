<template>
  <svg id="Layer_1" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
    <path id="Paper" clip-rule="evenodd" d="m14 5c0-.6-.4-1-1-1s-1 .4-1 1v2c0 1.7 1.3 3 3 3h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1-.4-1-1zm-1.9-4c.6 0 1.1 0 1.6.1.4.1.8.3 1.2.5s.7.6 1.2 1l3.3 3.4c.4.4.8.8 1 1.2s.4.7.5 1.2c.1.4.1.9.1 1.5v7.4 2c0 .6-.1 1.1-.4 1.6-.4.8-1 1.4-1.7 1.7-.5.2-1 .3-1.6.4-.5 0-1.2 0-2 0h-6.5c-.8 0-1.5 0-2 0-.6 0-1.1-.1-1.6-.4-.8-.4-1.4-1-1.7-1.7-.2-.5-.3-1-.4-1.6 0-.5 0-1.2 0-2v-10.5c0-.8 0-1.5 0-2 0-.6.1-1.1.4-1.6.4-.8 1-1.4 1.7-1.7.5-.2 1-.3 1.6-.4h2z" fill="#1089ff" fill-rule="evenodd"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'iconFiles'
      },
      width: {
        type: [Number, String],
        default: 512
      },
      height: {
        type: [Number, String],
        default: 512
      },
      iconColor: {
        type: String,
        default: '#5784eb'
      }
    }
  }
</script>