<template>
  <v-dialog
    v-if="dialog_view"
    v-model="dialog"
    persistent
    max-width="430px"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="mode === 'small'"
        icon
        dark
        color="primary"
        v-on="on"
      >
        <v-icon>mdi-web</v-icon>
      </v-btn>
      <v-btn
        v-else-if="mode === 'small_white'"
        icon
        dark
        color="white"
        v-on="on"
        outlined
      >
        <v-icon>mdi-web</v-icon>
      </v-btn>
      <v-btn
        v-else-if="mode === 'small_btn'"
        class="small_btn justify-start"
        color="primary"
        v-on="on"
        elevation="24"
        text
        depressed
        block
      >
        <v-icon 
          left 
          color="primary" 
          size="22"
        >
          mdi-web
        </v-icon>
        <span 
          class="primary--text custom-span"
        >
          {{ $t('login.authorization_change_lang') }}
        </span>
      </v-btn>
    </template>
    <v-card class="pa-8">
      <v-card-title class="px-0 mb-3 text-body-2 text-sm-h6">
        <span class="">{{ $t('language_settings.choose_lang') }}</span>
      </v-card-title>
      <v-card-text class="pa-0">
        {{ /*Выбор языка */ }}
        <v-select
          v-model="current_lang"
          :items="lang_list"
          item-text="text"
          item-value="value"
          return-value
          solo
          append-icon="mdi-chevron-down"
        ></v-select>
      </v-card-text>

      <form class="text-right">
        <v-btn color="primaryBlue mr-2" text @click="dialog = false">{{ $t('common.close') }}</v-btn>
        <v-btn color="primary" elevation="0" @click="saveSettings">{{ $t('common.save') }}</v-btn>
      </form>

    </v-card>
  </v-dialog>

  <v-menu
    v-else
    rounded="b-xl"
    offset-y
    transition="slide-y-transition"
    v-model="dialog"
    content-class="elevation-24"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-if="mode === 'small'"
        icon
        dark
        color="primary"
        v-on="on"
      >
        <v-icon>mdi-web</v-icon>
      </v-btn>
      <v-btn
        v-else-if="mode === 'small_white'"
        icon
        dark
        color="white"
        v-on="on"
      >
        <v-icon>mdi-web</v-icon>
      </v-btn>
      <v-btn
        v-else-if="mode === 'small_btn'"
        class="small_btn"
        color="white"
        v-on="on"
        elevation="24"
      >
        <v-icon left>mdi-web</v-icon>
        {{ $t('login.authorization_change_lang') }}
      </v-btn>
    </template>

    <v-list
      dense
    >
      <v-list-item-group
        v-model="current_lang"
        color="primary"
        @change="saveSettings()"
      >
        <v-list-item
          v-for="(lang, i) in lang_list"
          :key="i"
          :value="lang.value"
        >
          <v-list-item-title v-text="lang.text"></v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import i18n from "@/lang/i18n";

export default {

  data: function () {
    return {
      dialog: false,
      current_lang: 'uk',
      lang_list: [
        {"text": "Українська", "value": "uk"},
        {"text": "Русский", "value": "ru"},
        {"text": "English", "value": "en"},
        {"text": "Deutsche", "value": "de"},
        {"text": "Vietnamese", "value": "vn"},
      ]
    }
  },

  props: {
    mode: {
      type: String,
      default: 'small_btn',
      required: true,
    },
    width: {
      type: String,
      default: '430'
    },
    dialog_view: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    //Сохранение настроек
    saveSettings() {
      this.$setCookie('user_lang', this.current_lang, {'expires': 10 * 24 * 60 * 60 * 10000})
      this.dialog = false;
      //перезагрузка страницы
      location.reload();
    },

    checkQueryExistInAvailableLocales() {
      if (
        !Object.keys(this.$route?.query).length
        && this.$route?.name !== 'Login'
      ) return

      let lang = ''
      let params = this.$route.query

      for (let param in params) {
        if (
          i18n?.availableLocales.includes(param)
          && params[param] === null
        ) lang = param
      }

      if (lang) {
        this.$setCookie( 'user_lang', lang, {'expires': 10*24*60*60*10000} )
        i18n.locale = lang
        this.current_lang = lang
        this.$router.replace('/')
        location.reload();
      }
    },
  },

  created() {
    //Check query in URL for changing lang
    this.checkQueryExistInAvailableLocales()
    //end Check query in URL for changing lang

    //Получение языка из куки
    let user_lang = this.$getCookie('user_lang')
    //Если кука не установлена - создаем новую. По умолчанию язык русский. Предлагаем выбрать язык
    if (!user_lang) {
      this.$setCookie('user_lang', 'uk', {'expires': 10 * 24 * 60 * 60 * 10000})
      this.$nextTick(() => {
        this.dialog = true; //выбор языка
      })
    } else {
      this.current_lang = user_lang
    }
  },
}
</script>

<style scoped>
  .custom-span {
    text-transform: none;
    padding-left: 8px;
  }
</style>
