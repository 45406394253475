import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/OperatorPanel/layout/DashboardsLayout.vue';

export default [
	{
		path: '/operator_panel',
		component: LAYOUT.base,
		name: 'OperatorPanel',
		children: [
			{
				path: 'statistic_real_time',
				name: 'OperatorPanelRealtimeBoard',
				component: () => import(/* webpackChunkName: 'op-chats' */ '@/modules/OperatorPanel/views/dashboard/RealtimeBoard.vue'),
			},
		],
	},
];
