<template>
    <svg
      :width="width"
      :height="height" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00959 9.50952L11.1918 9.50952L11.1918 15.6917H9.79179V11.8995L1.24299 20.4482L0.253042 19.4583L8.80184 10.9095L5.00959 10.9095L5.00959 9.50952Z" :fill="iconColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8852 2.7284L15.3497 6.26394L14.3597 5.27399L17.8953 1.73845L18.8852 2.7284Z" :fill="iconColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3497 9.66532H15.3497V8.26532H20.3497V9.66532Z" :fill="iconColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4228 15.7257L13.8873 12.1902L14.8772 11.2003L18.4128 14.7358L17.4228 15.7257Z" :fill="iconColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.47312 6.77652L4.93759 3.24099L5.92754 2.25104L9.46307 5.78657L8.47312 6.77652Z" :fill="iconColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9039 5.22833V0.228333L12.3039 0.228333V5.22833H10.9039Z" :fill="iconColor"/>
    </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconLsAction'
    },
    width: {
      type: [Number, String],
      default: 21
    },
    height: {
      type: [Number, String],
      default: 21
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>