<template>
  <div class="cd__map" v-if="viewMap">
    <GmapMap
      :center="{lat: +mapObject.latitude, lng: +mapObject.longitude}"
      :zoom="10"
      map-type-id="roadmap"
      style="width: 100%; height: 400px"
      :options="{
        scrollwheel: false
      }"
    >
      <GmapMarker
          label="★"
          :position="{
            lat: +mapObject.latitude,
            lng: +mapObject.longitude,
          }"
        />
    </GmapMap>
  </div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps'

export default  {
  name: 'CdMap',

  props: {
    mapObject: {
      type: Object
    },
  },

  data() {
    return {
      viewMap: false
    };
  },

  computed: {
    
  },

  beforeMount () {
    if (typeof google !== 'object') {
      VueGoogleMaps.loadGmapApi({
        key: '',
        libraries: 'places'
      })
    }
  },

  mounted() {
    this.updateMap();
  },

  methods: {
    updateMap() {
      this.viewMap = false
      this.viewMap = true
    }
  },

  watch: {
    mapObject: {
      handler: 'updateMap',
      deep: true,
    },
  },

}
</script>

<style scoped lang="scss">

</style>
