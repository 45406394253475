<template>
  <svg :width="width" :height="height" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12L0.669872 7.5L9.33013 7.5L5 12Z" fill="#C4C4C4"/>
    <path d="M5 0L9.33013 4.5H0.669873L5 0Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconXls",
    },
    width: {
      type: [Number, String],
      default: 10,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
