import Vue from 'vue';
import axios from 'axios/index';
import i18n from '@/lang/i18n.js';
import { router } from "@/main";

const state = {
  statistics: {
    statisticsFullData: {},
    statisticsByUsers: {},
    statisticsUsersDialogsFreq: [],
		filterByAllBots: false,
  },
};

const mutations = {
  SET_STATISTICS_FULL_DATA (state, payload) {
    Vue.set(state.statistics, 'statisticsFullData', payload);
  },
  SET_STATISTICS_BY_USERS (state, payload) {
    Vue.set(state.statistics, 'statisticsByUsers', payload);
  },
  SET_STATISTICS_USERS_DIALOGS_FREQ(state, payload) {
    Vue.set(state.statistics, 'statisticsUsersDialogsFreq', payload);
  },
	TOGGLE_FILTER_BY_ALL_BOTS(state, boolean) {
		state.statistics.filterByAllBots = boolean
	},
};

const actions = {

  async statisticsGetFullData ({state, dispatch, commit}, query) {
    const url = state.engine_settings.active_url;
    const token = Vue.$cookies.get('token_e');
    const bot_id = parseInt(router.currentRoute?.params?.id || state.bot_settings.active_id);
    const request = {
      bot_id,
    };

    let filter = null
    if (query && query.length) {
      let dayTo = new Date(query[1])
      //check if date with time
      if ( /\s/.test(query[0]) ) {
        dayTo = query[1]
      } else {
        //increase date_to to One day, for request on back
        dayTo = new Date(dayTo.setDate(dayTo.getDate() + 1)).toISOString().slice(0, 10)
      }

      filter = {
        params: {
          filter: {
            "updatedAt|date_from": query[0],
            "updatedAt|date_to": dayTo
          }
        }
      }
    }

    dispatch('updateAjaxDialog', [true, true]);

    await axios
      .post(url + '/kw/statistics/full', request, filter,{
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
      .then(
        (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if (response.data && response.data.data) {
            commit('SET_STATISTICS_FULL_DATA', response.data.data)
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [
            err,
            i18n.t('common.error'),
            i18n.t('modules.statistics.texts.error_get_stat_data'),
          ]);
        }
      );
  },

  async statisticsGetByUsers ({state, dispatch, commit}, query) {
    const url = state.engine_settings.active_url;
    const token = Vue.$cookies.get('token_e');
    const bot_id = parseInt(router.currentRoute?.params?.id || state.bot_settings.active_id);
    const request = {};

    let dayTo = new Date(query[1])
    //check if date with time
    if ( /\s/.test(query[0]) ) {
      dayTo = query[1]
    } else {
      //increase date_to to One day, for request on back
      dayTo = new Date(dayTo.setDate(dayTo.getDate() + 1)).toISOString().slice(0, 10)
    }

    let filter = null
    if (query && query.length) {
      filter = {
        params: {
          filter: {
            "updatedAt|date_from": query[0],
            "updatedAt|date_to": dayTo
          },
          bot_id: bot_id,
        }
      }
    }

    dispatch('updateAjaxDialog', [true, true]);

    await axios
      .post(url + '/kw/statistics/byUsers', request, filter,{
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
      .then(
        (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if (response.data && response.data.data) {
            commit('SET_STATISTICS_BY_USERS', response.data.data)
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [
            err,
            i18n.t('common.error'),
            i18n.t('modules.statistics.texts.error_get_stat_data'),
          ]);
        }
      );
  },

  async axiosStatisticsUsersDialogsFreq({ state, dispatch, commit }, query) {
    const url = state.engine_settings.active_url;
    const token = Vue.$cookies.get('token_e');
    const bot_id = parseInt(router.currentRoute?.params?.id || state.bot_settings.active_id);
    const request = {};

    //increase date_to to One day, for request on back
    let dayTo = new Date(query[1])
    //check if date with time
    if (query[1].split(' ').length > 1) {
      dayTo = query[1]
    } else {
      dayTo = new Date(dayTo.setDate(dayTo.getDate() + 1)).toISOString().slice(0, 10)
    }

    let filter = null
    if (query && query.length) {
      filter = {
        params: {
          filter: {
            "updatedAt|date_from": query[0],
            "updatedAt|date_to": dayTo
          },
          bot_id: bot_id,
        }
      }
    }

    dispatch('updateAjaxDialog', [true, true]);

    await axios
      .post(url + '/kw/statistics/dialogs_freq', request, filter,{
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
      .then(
        (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if (response.data && response.data.data) {
            commit('SET_STATISTICS_USERS_DIALOGS_FREQ', response.data.data)
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [
            err,
            i18n.t('common.error'),
            i18n.t('modules.statistics.texts.error_get_stat_data'),
          ]);
        }
      );
  },

  axiosExportStatisticsUsersDialogsFreq({ state, dispatch, commit }, data) {
    const url = state.engine_settings.active_url;
    const token = Vue.$cookies.get('token_e');
    const bot_id = parseInt(router.currentRoute?.params?.id || state.bot_settings.active_id);
    const request = {};

    let {query, format} = {...data}

    let dayTo = new Date(query[1])
    //check if date with time
    if ( /\s/.test(query[0]) ) {
      dayTo = query[1]
    } else {
      //increase date_to to One day, for request on back
      dayTo = new Date(dayTo.setDate(dayTo.getDate() + 1)).toISOString().slice(0, 10)
    }

    let filter = null
    if (query && query.length) {
      filter = {
        params: {
          filter: {
            "updatedAt|date_from": query[0],
            "updatedAt|date_to": dayTo
          },
          format: format,
          bot_id: bot_id,
        }
      }
    }

    dispatch('updateAjaxDialog', [true, true]);

    return new Promise((resolve, reject) => {
      axios.post(url + '/kw/statistics/dialogs_freq', request, filter,{
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
        .then((response) => {
          dispatch('updateAjaxDialog', [false, false]);
          resolve(response);
        })
        .catch((error) => {
          dispatch('updateAjaxDialogError', [
            error,
            i18n.t('common.error'),
            i18n.t('modules.statistics.texts.error_get_stat_data'),
          ]);

          reject(error);
        });
    });
  },

	toggleSearchByAllBots( {commit}, boolean ) {
		commit('TOGGLE_FILTER_BY_ALL_BOTS', boolean);
	}
};

const getters = {
  statisticsFullData: (state) => state.statistics.statisticsFullData,
  statisticsByUsers: (state) => state.statistics.statisticsByUsers,
  statisticsUsersDialogsFreq: (state) => state.statistics.statisticsUsersDialogsFreq,
	statisticsFilterByAllBots: (state) => state.statistics.filterByAllBots,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
