<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5721 0.398438H7.36515C3.55694 0.398438 0.459961 3.49542 0.459961 7.30363V21.114C0.459961 21.4192 0.581212 21.712 0.797042 21.9278C1.01287 22.1436 1.3056 22.2649 1.61083 22.2649H16.5721C20.3803 22.2649 23.4773 19.1679 23.4773 15.3597V7.30363C23.4773 3.49542 20.3803 0.398438 16.5721 0.398438ZM21.1755 15.3597C21.1755 17.8985 19.1109 19.9632 16.5721 19.9632H2.76169V7.30363C2.76169 4.76482 4.82634 2.70017 7.36515 2.70017H16.5721C19.1109 2.70017 21.1755 4.76482 21.1755 7.30363V15.3597Z"
              :fill="iconColor"/>
        <path d="M9.09153 13.058C10.0449 13.058 10.8178 12.2851 10.8178 11.3317C10.8178 10.3783 10.0449 9.60536 9.09153 9.60536C8.13812 9.60536 7.36523 10.3783 7.36523 11.3317C7.36523 12.2851 8.13812 13.058 9.09153 13.058Z"
              :fill="iconColor"/>
        <path d="M14.8459 13.058C15.7993 13.058 16.5722 12.2851 16.5722 11.3317C16.5722 10.3783 15.7993 9.60536 14.8459 9.60536C13.8925 9.60536 13.1196 10.3783 13.1196 11.3317C13.1196 12.2851 13.8925 13.058 14.8459 13.058Z"
              :fill="iconColor"/>
    </svg>

</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconUserInputTollbox'
            },
            width: {
                type: [Number, String],
                default: 24
            },
            height: {
                type: [Number, String],
                default: 23
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>