import Vue from "vue";
import axios from "axios";

// Do something before request is sent
const requestSent = function beforeRequestSent(config) {
  const token = Vue.$cookies.get("token_c");

  config.headers["X-CSRF-TOKEN"] = token;

  return config;
};

// Do something with request error
const requestError = function beforeRequestSentError(error) {
  return Promise.reject(error);
};

// Do something with response data
const responseGet = function beforeResponseGet(response) {
  return response;
};

// Do something with response error
const responseError = function beforeResponseGetError(error) {
  return Promise.reject(error);
};

const instance = axios.create({});

// Add a request interceptor
instance.interceptors.request.use(requestSent, requestError);

// Add a response interceptor
instance.interceptors.response.use(responseGet, responseError);

export default instance;
