<template>
  <div class="global-filter__item">
    <v-select
      v-model="value"
      :items="filterData.conditions"
      item-text="text" 
      item-value="value"
      height="40"
      label="Выберите условие"
      outlined
      hide-details
      class="global-filter__item-select"
    ></v-select>
    <v-text-field
      outlined
      dense
      class="global-filter__item-input"
      v-model="modelValue"
      :placeholder="returnFilterKeyOrLabel(filterData)"
      hide-details
      height="40"
    ></v-text-field>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import i18n from "@/lang/i18n";

export default  {
  name: 'GlobalFilterItem',

  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    i18n,
    value: null,
  }),

  computed: {
    ...mapGetters([]),
  },

  mounted() {
    console.log(this.filterData)
  },

  methods: {
    ...mapActions([
    ]),

    returnFilterKeyOrLabel(item) {
      return item?.label ? item?.label : item.name
    },
  },
}
</script>

<style scoped lang="scss">
.global-filter__item {
  .v-input + .v-input {
    margin-top: 8px;
  }
  &-select {
    &::v-deep .v-input__slot {
      min-height: 40px;
    }
    &::v-deep .v-input__append-inner {
      margin-top: 9px;
    }
    &::v-deep .v-label {
      top: 10px;
      &.v-label-active {
        top: 18px;
      }
    }
    &::v-deep .v-label {
      top: 10px;
      &.v-label--active {
        top: 16px;
      }
    }
  }
}
</style>
