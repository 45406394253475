/**
 * Created by Bezsmertnyi Ievgen on 12.09.2020.
 */

const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'Bids';

let module_settings = {
  module_name,
};
//Корневой урл модуля
let module_alias = 'bids';

//Полный список разрешений модуля
module_settings.permits = {
  front: {
    //Отображение пунктов меню
    registered_users_show_menu_item: {
      description: 'Отображать в меню пункт зарегистрированных пользователей',
    },
    //Отображение табов
    registered_users_show_messengers_tab_item: {
      description:
        'Отображать таб мессенджеров зарегистрированных пользователей',
    },
  },

  back: {},
};

//Пункты в меню
module_settings.menu_items = [
  {
    title: getTranslate('modules.bids.module_settings.bid'),
    module: module_name,
    alias: module_alias,
    icon: 'mdi-gondola',
    show: true,
    route: 'bids',
  },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate('modules.bids.module_settings.bids'),
      content: '',
      show: true,
      type: 'bids',
      alias: '/',
      permits: {
        show: 'registered_users_show_messengers_tab_item',
      },
      route: 'section',
    },
  ],
};

//Подключенные компоненты
module_settings.components = [
  {
    name: module_name,
    path: 'Component/Bids.vue',
  },
];

//Экспорт для vue и для node
module.exports = { module_settings };
module.exports.default = { module_settings };
