<template>
  <div>
    <v-alert
      border="left"
      color="indigo"
      dark
    >
      <v-expansion-panels
        flat
        hover
        focusable
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{$t('modules.customdata.onboarding_screen.create_ai_magic_data.header')}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <CreateAiMagicDataForm />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-alert>
  </div>

</template>

<script>
import CreateAiMagicDataForm from "@/modules/CustomData/components/CreateAiMagicDataForm.vue";

export default {
  name: 'CreateAiMagicData',

  components: {
    CreateAiMagicDataForm,
  },

  data() {
    return {

    }
  },
}
</script>

<style scoped lang="scss">

</style>