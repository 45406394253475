<template>
    <svg
        :width="width"
        :height="height"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.4 22.31">
        <g>
            <path
                  d="M19.12,7a3,3,0,0,0,0-.43,3,3,0,0,0,0-.43l1-.89a.7.7,0,0,0,.14-.88L19,2.19a.7.7,0,0,0-.81-.31l-1.39.45a5.13,5.13,0,0,0-.72-.41L15.79.56A.7.7,0,0,0,15.11,0H12.48a.7.7,0,0,0-.68.56l-.28,1.36a5.13,5.13,0,0,0-.72.41L9.41,1.88a.69.69,0,0,0-.81.31l-1,1.41a4.9,4.9,0,0,0-1.48,0A4.82,4.82,0,0,0,3.7,5a4.81,4.81,0,0,0,.72,7.41,4.82,4.82,0,0,0,2.68.81,4.85,4.85,0,0,0,3.4-1.41,5.19,5.19,0,0,0,.56-.68l.47.2.27,1.33a.7.7,0,0,0,.68.57h2.63a.7.7,0,0,0,.68-.57l.28-1.35a5.2,5.2,0,0,0,.72-.42l1.39.46A.68.68,0,0,0,19,11L20.3,8.79a.7.7,0,0,0-.14-.88ZM10.23,9.67a3.19,3.19,0,0,1-.74,1.1,3.19,3.19,0,0,1-1.1.74,3.43,3.43,0,0,1-3.18-.32A3.3,3.3,0,0,1,4,9.67a3.37,3.37,0,0,1-.2-2A3.45,3.45,0,0,1,4.7,6a3.37,3.37,0,0,1,1.74-.93A3.68,3.68,0,0,1,7.1,5a3.49,3.49,0,0,1,1.15.21A3.72,3.72,0,0,1,9.88,6.44a4.15,4.15,0,0,1,.47,1,3.29,3.29,0,0,1,.14.94A3.38,3.38,0,0,1,10.23,9.67ZM17.7,6.12a2.42,2.42,0,0,1,.06.48,2.63,2.63,0,0,1-.06.48l0,.11a.7.7,0,0,0,.24.65l.88.75-.73,1.23-1.18-.4a.71.71,0,0,0-.66.12,4.05,4.05,0,0,1-1,.59.7.7,0,0,0-.43.51l-.24,1.16H13.06l-.24-1.16a.67.67,0,0,0-.44-.51l-.19-.08a4.7,4.7,0,0,1-.45-.17A4,4,0,0,0,12,8.68c0-.2,0-.41,0-.61a1.29,1.29,0,0,0,.19.21,2.42,2.42,0,0,0,3.37,0h0a2.38,2.38,0,0,0,0-3.37,2.44,2.44,0,0,0-3.37,0,2.42,2.42,0,0,0-.68,1.38,3.84,3.84,0,0,0-.33-.59,4.77,4.77,0,0,0-2-1.7l.43-.62,1.19.39a.71.71,0,0,0,.66-.11,3.77,3.77,0,0,1,1-.59.71.71,0,0,0,.43-.51l.24-1.16h1.47l.24,1.16a.71.71,0,0,0,.43.51,3.77,3.77,0,0,1,1,.59.71.71,0,0,0,.66.11l1.18-.39.73,1.23-.88.74a.7.7,0,0,0-.24.65Zm-4.89.48a1,1,0,0,1,.29-.7,1,1,0,0,1,1.39,0,1,1,0,0,1,.29.7.94.94,0,0,1-.29.69h0a1,1,0,0,1-1.39,0A.94.94,0,0,1,12.81,6.6Z"
                  :fill="iconColor"
            />
            <path
                  d="M9.38,13.82H4.82A4.81,4.81,0,0,0,0,18.64v3.67H14.19V18.64a4.83,4.83,0,0,0-4.82-4.82Zm3.39,7.07H1.43V18.64a3.38,3.38,0,0,1,3.39-3.39H9.38a3.38,3.38,0,0,1,3.39,3.39Z"
                  :fill="iconColor"
            />
        </g>
    </svg>

</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconMenuOpSettings'
            },
            width: {
                type: [Number, String],
                default: 22
            },
            height: {
                type: [Number, String],
                default: 22
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>