import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/Statistics/layout/Layout.vue';

export default [
  {
    path: '/statistics_settings',
    component: LAYOUT.base,
    redirect: '/statistics_settings/funnels',
    children: [
      {
        path: 'funnels',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'StatisticsSettingsFunnels',
            component: () => import(/* webpackChunkName: 'statistics-settings-funnels' */ '@/modules/Statistics/views/settings/FunnelsTab.vue'),
          },
        ],
      },
      {
        path: 'targets',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'StatisticsSettingsTargets',
            component: () => import(/* webpackChunkName: 'statistics-settings-targets' */ '@/modules/Statistics/views/settings/TargetsTab.vue'),
          },
        ],
      },
    ],
  },
];
