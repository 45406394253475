<template>
    <svg
      :width="width"
      :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9 18.7332H7.09995H16.9ZM16.9 14.5332H15.5H16.9ZM12.7 14.5332H11.3H12.7ZM8.49995 14.5332H7.09995H8.49995ZM16.9 11.7332H15.5H16.9ZM12.7 11.7332H11.3H12.7ZM8.49995 11.7332H7.09995H8.49995ZM12 8.93324V2.63324V8.93324ZM20.4 8.93324H3.59995C3.22865 8.93324 2.87255 9.08074 2.61 9.34329C2.34745 9.60584 2.19995 9.96194 2.19995 10.3332V20.1332C2.19995 20.5045 2.34745 20.8606 2.61 21.1232C2.87255 21.3857 3.22865 21.5332 3.59995 21.5332H20.4C20.7713 21.5332 21.1273 21.3857 21.3899 21.1232C21.6525 20.8606 21.8 20.5045 21.8 20.1332V10.3332C21.8 9.96194 21.6525 9.60584 21.3899 9.34329C21.1273 9.08074 20.7713 8.93324 20.4 8.93324Z" :stroke="iconColor" stroke-width="1.4"/>
    </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconLsKeyboard'
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 25
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>