<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.13333 16.8564H5.86667V16.1231H5.13333V16.8564ZM5.13333 21.2535H4.4C4.4 21.3897 4.43792 21.5232 4.50952 21.639C4.58112 21.7549 4.68357 21.8485 4.80538 21.9094C4.92719 21.9703 5.06355 21.9961 5.19919 21.9838C5.33483 21.9716 5.46438 21.9218 5.57333 21.8401L5.13333 21.2535ZM11 16.8564V16.1231H10.7551L10.56 16.2697L11 16.8564ZM6.6 5.86227H5.86667V7.32893H6.6V5.86227ZM15.4 7.32893H16.1333V5.86227H15.4V7.32893ZM6.6 10.2593H5.86667V11.726H6.6V10.2593ZM12.4667 11.726H13.2V10.2593H12.4667V11.726ZM4.4 16.8564V21.2535H5.86667V16.8564H4.4ZM5.57333 21.8401L11.44 17.4431L10.56 16.2697L4.69333 20.6668L5.57333 21.8401ZM11 17.5897H19.8V16.1231H11V17.5897ZM19.8 17.5897C21.0159 17.5897 22 16.6071 22 15.3897H20.5333C20.5333 15.796 20.2063 16.1231 19.8 16.1231V17.5897ZM22 15.3897V2.2H20.5333V15.3912H22V15.3897ZM22 2.2C22 0.982667 21.0159 0 19.8 0V1.46667C20.2063 1.46667 20.5333 1.79373 20.5333 2.2H22ZM19.8 0H2.2V1.46667H19.8V0ZM2.2 0C0.984133 0 0 0.982667 0 2.2H1.46667C1.46667 1.79373 1.79373 1.46667 2.2 1.46667V0ZM0 2.2V15.3897H1.46667V2.2H0ZM0 15.3897C0 16.6071 0.984133 17.5897 2.2 17.5897V16.1231C2.10364 16.1233 2.0082 16.1044 1.91914 16.0676C1.83008 16.0309 1.74916 15.9768 1.68102 15.9087C1.61289 15.8406 1.55888 15.7597 1.52209 15.6706C1.48531 15.5815 1.46647 15.4861 1.46667 15.3897H0ZM2.2 17.5897H5.13333V16.1231H2.2V17.5897ZM6.6 7.32893H15.4V5.86227H6.6V7.32893ZM6.6 11.726H12.4667V10.2593H6.6V11.726Z" :fill="iconColor"/>
    </svg>

</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconMenuDialog'
            },
            width: {
                type: [Number, String],
                default: 22
            },
            height: {
                type: [Number, String],
                default: 22
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>