<template>
  <div>
    <vue-scroll>
      <div class="cd__image-slider">
        <CdImageSliderItem :imageUrl="item.url" v-for="item of imageSliderObject" :key="item.id" />
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import CdImageSliderItem from './CdImageSliderItem.vue';

export default  {
  name: 'CdImageSlider',

  components: {
    CdImageSliderItem,
  },

  props: {
    imageSliderObject: {
      type: Array
    }
  },
  data: () => ({
    
  }),

  computed: {
    
  },

  mounted() {
    
  },

  methods: {
    
  },
}
</script>

<style scoped lang="scss">
.cd__image-slider {
  display: flex;
  gap: 16px;
  height: 400px;
}
</style>
