<template>
  <div class="ct__filters">
    <v-expansion-panels
      accordion
      multiple
      tile
      class="ct__filters--accordions"
      v-model="panelModel"
    >
      <v-expansion-panel
        v-for="(filter, index) of filtersList"
        :key="index"
      >
        <v-expansion-panel-header><span>{{ returnFilterKeyOrLabel(filter) }}</span></v-expansion-panel-header>
        <v-expansion-panel-content>
          <GlobalFilterItem
            :filter-data="filter"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import { mapGetters  } from "vuex";
  import GlobalFilterItem from "./GlobalFilterItem.vue";
  import i18n from "@/lang/i18n";

  export default  {
    name: 'GlobalFilterContent',

    components: {
      GlobalFilterItem,
    },

    data: () => ({
      i18n,
      panelModel: [],
    }),

    computed: {
      ...mapGetters([
        'custom_data_filters',
      ]),
      filtersList() {
        return this.custom_data_filters.filter(obj => obj.hidden_all !== 'true')
      }
    },

    methods: {
      returnFilterKeyOrLabel(item) {
        return item?.label ? item?.label : item.name
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ct__filters {
    flex: auto;

    .__rail-is-vertical {
      width: 2px !important;
    }

    .v-expansion-panels::v-deep {
      .v-expansion-panel::before {
        box-shadow: none;
      }

      .v-expansion-panel-header {
        padding: 12px 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        span{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      [aria-expanded="true"] {
        .v-expansion-panel-header {
          min-height: 48px;
        }

        .v-expansion-panel-header__icon .v-icon {
          color: var(--black-base);
        }
      }

      .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
          padding: 10px 0 16px 0;
        }
      }
    }

    /* Checkboxes */
    .v-input--radio-group::v-deep,
    .v-input--checkbox::v-deep {
      .v-input--dense {
        margin-top: 6px;
      }

      .v-icon {
        font-size: 24px;
        color: var(--v-primary-base);
      }

      .theme--light.v-label {
        color: var(--v-black-base);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
</style>