<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path d="M5.13328 12.4667V8.06667H2.19994C1.81096 8.06667 1.43791 8.22119 1.16285 8.49625C0.8878 8.7713 0.733276 9.14435 0.733276 9.53334V19.8C0.733276 20.189 0.8878 20.562 1.16285 20.8371C1.43791 21.1121 1.81096 21.2667 2.19994 21.2667H15.3999C15.7889 21.2667 16.162 21.1121 16.437 20.8371C16.7121 20.562 16.8666 20.189 16.8666 19.8V13.9333H6.59994M5.13328 12.4667V2.2C5.13328 1.81102 5.2878 1.43797 5.56285 1.16291C5.83791 0.887861 6.21096 0.733337 6.59994 0.733337H10.9999L13.9333 3.66667H19.7999C20.1889 3.66667 20.562 3.82119 20.837 4.09625C21.1121 4.3713 21.2666 4.74435 21.2666 5.13334V12.4667C21.2666 12.8557 21.1121 13.2287 20.837 13.5038C20.562 13.7788 20.1889 13.9333 19.7999 13.9333H6.59994L5.13328 12.4667ZM5.13328 12.4667C5.13328 12.8557 5.2878 13.2287 5.56285 13.5038C5.83791 13.7788 6.21096 13.9333 6.59994 13.9333L5.13328 12.4667Z"
                  :stroke="iconColor"
                  stroke-width="1.6"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="22" height="22" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconMenuFilemanager'
            },
            width: {
                type: [Number, String],
                default: 22
            },
            height: {
                type: [Number, String],
                default: 22
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>