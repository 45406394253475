<template>
  <div class="cd__image-item">
    <img :height="maxHeightInTable" :src="imageUrl" @click="openImage">

    <v-dialog v-model="dialog" max-width="100%">
      <div class="cd__image-item-big">
        <img :src="imageUrl" />
        <div class="cd__image-item-big-close">
          <v-btn icon @click="closeImage">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

export default  {
  name: 'CdImageListItem',

  props: {
    imageUrl: {
      type: String
    },
    maxHeightInTable: {
      type: String,
      default: '50',
    },
  },
  data: () => ({
    dialog: false,
  }),

  methods: {
    openImage() {
      this.dialog = true;
    },
    closeImage() {
      this.dialog = false;
    },
  },
}
</script>

<style scoped lang="scss">
.cd__image-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
  }

  &-big {
    height: 90vh;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: auto;
      height: auto;
      max-height: 100%;
      object-fit: contain;
    }

    &-close {
      background-color: #fff;
      border-radius: 100%;
      position: absolute;
      right: 24px;
      top: 24px;
      z-index: 3;
    }
  }
}
</style>
