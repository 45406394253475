<template>
  <svg id="Layer_1" :height="height" viewBox="0 0 32 32" :width="width" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
    <g fill="#141e26"><circle cx="25.502" cy="12" r="1"/><circle cx="25.502" cy="15.763" r="1"/><circle cx="25.502" cy="8.237" r="1"/></g><path d="m13.617 17.193c-.489 0-.979-.119-1.424-.36-.968-.523-1.568-1.531-1.568-2.631v-4.877c0-1.103.604-2.112 1.574-2.634.971-.522 2.146-.47 3.067.139l3.709 2.453c.843.557 1.344 1.493 1.342 2.503-.003 1.01-.509 1.943-1.354 2.496l-3.71 2.425c-.496.325-1.065.488-1.636.488zm.008-8.864c-.216 0-.389.075-.479.123-.156.084-.521.338-.521.873v4.877c0 .533.363.787.52.872.157.084.569.248 1.015-.042l3.71-2.425c.284-.186.447-.487.448-.827 0-.34-.161-.643-.445-.83l-3.709-2.453c-.191-.126-.377-.168-.538-.168z" fill="#0511f2"/><g fill="#141e26"><path d="m19.003 29h-16.003c-.552 0-1-.448-1-1 0-.552.448-1 1-1h16.003z"/><path d="m29 29h-5.997v-2h5.997c.552 0 1 .448 1 1 0 .552-.448 1-1 1z"/><path d="m26 23h-20c-2.757 0-5-2.243-5-5v-12c0-2.757 2.243-5 5-5h20c2.757 0 5 2.243 5 5v12c0 2.757-2.243 5-5 5zm-20-20c-1.654 0-3 1.346-3 3v12c0 1.654 1.346 3 3 3h20c1.654 0 3-1.346 3-3v-12c0-1.654-1.346-3-3-3z"/></g><path d="m21.003 31c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" fill="#0511f2"/>
  </svg>

</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'iconFileVideo'
      },
      width: {
        type: [Number, String],
        default: 512
      },
      height: {
        type: [Number, String],
        default: 512
      },
      iconColor: {
        type: String,
        default: '#5784eb'
      }
    }
  }
</script>