<template>
    <svg :width="width"
         :height="height" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.83569 21.0002V16.8002C6.83569 16.4289 6.98319 16.0728 7.24574 15.8102C7.50829 15.5477 7.86438 15.4002 8.23569 15.4002H13.8357C14.207 15.4002 14.5631 15.5477 14.8256 15.8102C15.0882 16.0728 15.2357 16.4289 15.2357 16.8002V21.0002H6.83569ZM19.4357 21.0002H2.63569C2.26438 21.0002 1.90829 20.8527 1.64574 20.5901C1.38319 20.3276 1.23569 19.9715 1.23569 19.6002V2.80019C1.23569 2.42889 1.38319 2.07279 1.64574 1.81024C1.90829 1.54769 2.26438 1.40019 2.63569 1.40019H14.6561C15.0274 1.40027 15.3834 1.54782 15.6459 1.81039L20.4255 6.58999C20.6881 6.85248 20.8356 7.20852 20.8357 7.57979V19.6002C20.8357 19.9715 20.6882 20.3276 20.4256 20.5901C20.1631 20.8527 19.807 21.0002 19.4357 21.0002Z"
              :stroke="iconColor" stroke-width="1.4"/>
    </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconSave'
            },
            width: {
                type: [Number, String],
                default: 22
            },
            height: {
                type: [Number, String],
                default: 22
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>