<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9 11.1441V14.5441M5.1 4.34408V2.64408C5.1 2.3435 5.2194 2.05523 5.43195 1.84269C5.64449 1.63015 5.93275 1.51074 6.23333 1.51074H10.7667C11.0672 1.51074 11.3555 1.63015 11.5681 1.84269C11.7806 2.05523 11.9 2.3435 11.9 2.64408V4.34408H5.1ZM0 4.91074H17H0ZM1.7 4.91074V16.2441C1.7 16.5447 1.8194 16.8329 2.03195 17.0455C2.24449 17.258 2.53275 17.3774 2.83333 17.3774H14.1667C14.4672 17.3774 14.7555 17.258 14.9681 17.0455C15.1806 16.8329 15.3 16.5447 15.3 16.2441V4.91074H1.7ZM8.5 8.87741V14.5441V8.87741ZM5.1 11.1441V14.5441V11.1441Z"
              :stroke="iconColor"
              stroke-width="1.4"/>
    </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconDelete'
            },
            width: {
                type: [Number, String],
                default: 17
            },
            height: {
                type: [Number, String],
                default: 18
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>