import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/FileManager/layout/Layout.vue';

export default [
  {
    path: '/file_manager',
    component: LAYOUT.base,
    redirect: '/file_manager/files',
    children: [
      {
        path: 'files',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'FileManagerFiles',
            component: () => import(/* webpackChunkName: 'file-manager-files' */ '@/modules/FileManager/views/FilesTab.vue'),
          },
        ],
      },
    ],
  },
];
