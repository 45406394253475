import axios from "../../axios-middleware";
import HELPERS from "@/modules/OperatorPanel/helpers";
import i18n from "@/lang/i18n.js";

const state = {
  operator_panel: {
    // current_user: {},
  },
};

const mutations = {
  // OP_SET_USER_INFO(state, userInfo) {
  //   Vue.set(state.operator_panel, "userInfo", userInfo);
  // },
};

const actions = {
  async opGetFileSettings({ dispatch }) {
    dispatch("updateAjaxDialog", [true, true]);

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/participants/add/--`;

    const settings = await axios
      .get(url)
      .then((response) => {
        dispatch("updateAjaxDialog", [false, false]);
        return response.data?.data?.settings || null;
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return null;
      });

    return settings;
  },

  async opSaveFileSettings({ dispatch }, settings) {
    // TODO: подключить апи
    dispatch("updateAjaxDialog", [true, true]);

    const { extensions, maxSize } = settings;

    const request = {
      extensions,
      max_size: maxSize,
    };

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/participants/add/--`;

    const success = await axios
      .post(url, request)
      .then((response) => {
        dispatch("updateAjaxDialog", [
          true,
          false,
            i18n.t("common.success"),
            i18n.t("modules.op.texts.settings_saved"),
        ]);

        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
            i18n.t("common.error"),
            i18n.t("modules.op.texts.settings_saved_error"),
        ]);

        return false;
      });

    return success;
  },
};

const getters = {
  // opUserInfo: (state) => state.operator_panel.userInfo,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
